.#{$rt-namespace}__toast-container {
    z-index: var(--toastify-z-index);
    -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
    position: fixed;
    padding: 4px;
    width: var(--toastify-toast-width);
    box-sizing: border-box;
    color: #e3e3e3;

    &--top-left {
        top: 1em;
        left: 1em;
    }

    &--top-center {
        top: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    &--top-right {
        top: 1em;
        right: 1em;
    }

    &--bottom-left {
        bottom: 1em;
        left: 1em;
    }

    &--bottom-center {
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    &--bottom-right {
        bottom: 1em;
        right: 1em;
    }
}

@media #{$rt-mobile} {
    .#{$rt-namespace}__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;

        &--top-left,
        &--top-center,
        &--top-right {
            top: 0;
            transform: translateX(0);
        }

        &--bottom-left,
        &--bottom-center,
        &--bottom-right {
            bottom: 0;
            transform: translateX(0);
        }

        &--rtl {
            right: 0;
            left: initial;
        }
    }
}

.#{$rt-namespace}__toast {
    &-theme--dark {
        background: $toastify-color-dark;
        color: $toastify-text-color-dark;
    }

    &-theme--light {
        background: $toastify-color-light;
        color: $toastify-text-color-light;
    }

    &-theme--colored--default {
        background: $toastify-color-light;
        color: $toastify-text-color-light;
    }

    &-theme--colored--info {
        color: $toastify-text-color-info;
        background: $toastify-color-info;
    }

    &-theme--colored--primary {
        color: $toastify-text-color-primary;
        background: $toastify-color-primary;
    }

    &-theme--colored--success {
        color: $toastify-text-color-success;
        background: $toastify-color-success;
    }

    &-theme--colored--warning {
        color: $toastify-text-color-warning;
        background: $toastify-color-warning;
    }

    &-theme--colored--error {
        color: $toastify-text-color-error;
        background: $toastify-color-error;
    }
}

.#{$rt-namespace}__progress-bar {
    &-theme--light {
        background: $toastify-color-progress-light;
    }

    &-theme--dark {
        background: $toastify-color-progress-dark;
    }

    &--info {
        color: $toastify-text-color-warning;
        background: $toastify-color-progress-info;
    }

    &--primary {
        color: $toastify-text-color-warning;
        background: $toastify-color-progress-primary;
    }

    &--success {
        background: $toastify-color-progress-success;
    }

    &--warning {
        background: $toastify-color-progress-warning;
    }

    &--error {
        background: $toastify-color-progress-error;
    }

    &-theme--colored#{&}--info,
    &-theme--colored#{&}--success,
    &-theme--colored#{&}--primary,
    &-theme--colored#{&}--warning,
    &-theme--colored#{&}--error {
        background: $toastify-color-transparent;
    }
}

.#{$rt-namespace}__progress-bar {
    &-theme--light {
        background: $toastify-color-progress-light;
    }

    &-theme--dark {
        background: $toastify-color-progress-dark;
    }

    &--info {
        background: $toastify-color-progress-info;
    }

    &--primary {
        background: $toastify-color-progress-primary;
    }

    &--success {
        background: $toastify-color-progress-success;
    }

    &--warning {
        background: $toastify-color-progress-warning;
    }

    &--error {
        background: $toastify-color-progress-error;
    }

    &-theme--colored#{&}--info,
    &-theme--colored#{&}--success,
    &-theme--colored#{&}--primary,
    &-theme--colored#{&}--warning,
    &-theme--colored#{&}--error {
        background: $toastify-color-transparent;
    }
}

@keyframes #{$rt-namespace}__trackProgress {
    0% {
        transform: scaleX(1);
    }

    100% {
        transform: scaleX(0);
    }
}

.#{$rt-namespace}__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: var(--toastify-z-index);
    opacity: 0.7;
    transform-origin: left;

    &--animated {
        animation: #{$rt-namespace}__trackProgress linear 1 forwards;
    }

    &--controlled {
        transition: transform 0.2s;
    }

    &--rtl {
        right: 0;
        left: initial;
        transform-origin: right;
    }
}

.#{$rt-namespace}__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;

    &--light {
        color: #000;
        opacity: 0.3;
    }

    &>svg {
        fill: currentColor;
        height: 16px;
        width: 14px;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.#{$rt-namespace}__spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: var(--toastify-spinner-color-empty-area);
    border-right-color: var(--toastify-spinner-color);
    animation: #{$rt-namespace}__spin 0.65s linear infinite;
}

@mixin timing-function {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes #{$rt-namespace}__bounceInRight {

    from,
    60%,
    75%,
    90%,
    to {
        @include timing-function;
    }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes #{$rt-namespace}__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes #{$rt-namespace}__bounceInLeft {

    from,
    60%,
    75%,
    90%,
    to {
        @include timing-function;
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    to {
        transform: none;
    }
}

@keyframes #{$rt-namespace}__bounceOutLeft {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes #{$rt-namespace}__bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        @include timing-function;
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes #{$rt-namespace}__bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}

@keyframes #{$rt-namespace}__bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        @include timing-function;
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    to {
        transform: none;
    }
}

@keyframes #{$rt-namespace}__bounceOutDown {
    20% {
        transform: translate3d(0, 10px, 0);
    }

    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, 2000px, 0);
    }
}

.#{$rt-namespace}__bounce-enter {

    &--top-left,
    &--bottom-left {
        animation-name: #{$rt-namespace}__bounceInLeft;
    }

    &--top-right,
    &--bottom-right {
        animation-name: #{$rt-namespace}__bounceInRight;
    }

    &--top-center {
        animation-name: #{$rt-namespace}__bounceInDown;
    }

    &--bottom-center {
        animation-name: #{$rt-namespace}__bounceInUp;
    }
}

.#{$rt-namespace}__bounce-exit {

    &--top-left,
    &--bottom-left {
        animation-name: #{$rt-namespace}__bounceOutLeft;
    }

    &--top-right,
    &--bottom-right {
        animation-name: #{$rt-namespace}__bounceOutRight;
    }

    &--top-center {
        animation-name: #{$rt-namespace}__bounceOutUp;
    }

    &--bottom-center {
        animation-name: #{$rt-namespace}__bounceOutDown;
    }
}

@mixin transform {
    transform: translate3d(0, 0, 0);
}

@keyframes #{$rt-namespace}__slideInRight {
    from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
    }

    to {
        @include transform;
    }
}

@keyframes #{$rt-namespace}__slideInLeft {
    from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }

    to {
        @include transform;
    }
}

@keyframes #{$rt-namespace}__slideInUp {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        @include transform;
    }
}

@keyframes #{$rt-namespace}__slideInDown {
    from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
    }

    to {
        @include transform;
    }
}

@keyframes #{$rt-namespace}__slideOutRight {
    from {
        @include transform;
    }

    to {
        visibility: hidden;
        transform: translate3d(110%, 0, 0);
    }
}

@keyframes #{$rt-namespace}__slideOutLeft {
    from {
        @include transform;
    }

    to {
        visibility: hidden;
        transform: translate3d(-110%, 0, 0);
    }
}

@keyframes #{$rt-namespace}__slideOutDown {
    from {
        @include transform;
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 500px, 0);
    }
}

@keyframes #{$rt-namespace}__slideOutUp {
    from {
        @include transform;
    }

    to {
        visibility: hidden;
        transform: translate3d(0, -500px, 0);
    }
}

.#{$rt-namespace}__slide-enter {

    &--top-left,
    &--bottom-left {
        animation-name: #{$rt-namespace}__slideInLeft;
    }

    &--top-right,
    &--bottom-right {
        animation-name: #{$rt-namespace}__slideInRight;
    }

    &--top-center {
        animation-name: #{$rt-namespace}__slideInDown;
    }

    &--bottom-center {
        animation-name: #{$rt-namespace}__slideInUp;
    }
}

.#{$rt-namespace}__slide-exit {

    &--top-left,
    &--bottom-left {
        animation-name: #{$rt-namespace}__slideOutLeft;
    }

    &--top-right,
    &--bottom-right {
        animation-name: #{$rt-namespace}__slideOutRight;
    }

    &--top-center {
        animation-name: #{$rt-namespace}__slideOutUp;
    }

    &--bottom-center {
        animation-name: #{$rt-namespace}__slideOutDown;
    }
}