.xRayParent {
  position: relative;
  margin-bottom: 20px;
}

.xRayRight {
  position: absolute;
  left: 72%;
  top: 40px;
  width: 31px;
  /* background: #3e3e3eD6; */
  height: 78%;
  z-index: 6;
  transition: .25s ease-in-out;
}

.xRaySlideRight {
  background: #3e3e3eD6;
  backdrop-filter: blur(5px);
  width: 60%;
  min-width: 500px;
  border-left: 2px solid #00e29f;
  border-right: 2px solid #00e29f;
  box-shadow: 3px 0 9px 0px #2c5c69;
}

.xRayRight .closeBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 4;
  padding: 5px;
  display: none;
}

.xRayRight:hover>.closeBtn {
  display: block;
  cursor: pointer
}

.xRayBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #3e3e3eD6;
  height: 18px;
  z-index: 1003;
  transition: .5s ease-in-out;
  border-top: 2px solid #00e29f;
  box-shadow: 0 0 9px 0px #2c5c69;
}

.xRaySlideOut {
  height: 35%;
  min-height: 300px;
}

.xRaySlideOutFull {
  height: 85%;
  min-height: 500px;
}

.xRayTitleBar {
  background-color: #3c3c3c !important;
  line-height: 10px;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  display: inline;

}

.xRaySegTypeRight {
  font-family: inherit;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.45rem;
  color: white;
  text-align: right !important;
}

.xRayTitleRight {
  cursor: pointer;
  font-family: inherit;
  line-height: 1.2;
  font-weight: 400;
  font-size: 16px;
  margin-left: 4px;
  margin-bottom: 0.45rem;
  color: #00e29f;
  text-align: left !important;
}

.xRayTitle {
  width: 20%;
  cursor: pointer;
  font-family: inherit;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 350;
  margin-bottom: 0.45rem;
  color: #00e29f;
  text-align: center !important;
}

.xRayName {
  width: 37%;
  font-family: inherit;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.45rem;
  color: white;
  text-align: left !important;
  margin-left: 30px !important
}

.xRaySegType {
  width: 35%;
  font-family: inherit;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.45rem;
  color: white;
  text-align: right !important;
}

.xRayTopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 25%;
  margin-right: 5px;
  max-height: 110px;
  transform: translateY(20%);
}

.xRayDetailsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 95%;
  text-align: center !important;
}

.xRayTransferSectionRight {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  bottom: 5px;
  text-align: center !important;
}

.xRayTransferSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 23%;
  text-align: center !important;
}

.xRayDataTransferSection {
  display: flex !important;
  width: 23%;
  margin-left: auto !important;
}

.xrayParamIcon {
  cursor: pointer;
  background-color: #3e3e3eD6;
  border: 1px solid #00e29f;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  padding: 3px 3px;
}

.xrayParamIcon:hover {
  box-shadow: 3px 3px 3px 1px #00e29f;
  cursor: pointer;
  background-color: #3e3e3e93;
  border: 1px solid #00e29f;
  color: #00e29f;
  font-size: 18px;
  padding: 2px 2px;
}

.xrayParamShow {
  margin-left: 10px;
  font-size: 18px;
  color: #ffffff;
  padding: 2px 2px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #eeeeee00;
}

.xrayParamShow:hover {
  background-color: #00e29fD6;
  border-color: #00e29f;
  border: 1px solid #00e29f;
  color: #2c5c69;
  border-radius: 5px;
  padding: 2px 2px;
}

.dataTransferItem {
  margin-left: 10px;
  font-size: 22px;
  color: #ffffff;
  /* color:#2c5c69; */
  padding: 2px 2px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #eeeeee00;
}

.dataTransferItem:hover {
  background-color: #00e29fD6;
  border: 1px solid #00e29f;
  color: #2c5c69;
  border-radius: 5px;
  padding: 2px 2px;
}

.dataTransferItem:active {
  border: 1px solid #00e29f;
  color: #00e29fD6;
  border-radius: 5px;
  padding: 2px 2px;
}

.xRayDetailRow {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  line-height: 1.4;
  ;
  font-weight: 400;
  font-size: 18px;
  margin-left: 6px !important;
  margin-right: 6px !important
}

.xRayDetail {
  font-family: inherit;
  line-height: 1.4;
  ;
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-align: center !important;
  margin-left: 4px !important
}

.xRayDetail2 {
  font-family: inherit;
  line-height: 1.4;
  ;
  font-weight: 400;
  font-size: 16px;
  color: white;
  /* text-align: center !important; */
  margin-left: 4px !important
}

.xRayDetailLabel {
  font-family: inherit;
  line-height: 1.4;
  ;
  font-weight: 300;
  font-size: 12px;
  color: white;
  text-align: center !important;
  margin-left: 8px !important
}

.xRayMainSection {
  top: 0;
  width: 100%;
  height: 65%;
  max-height: 210px;
  text-align: center !important;
}

.xRayMainSection .xRayBarChart {
  top: 0;
  width: 20%;
}

.xRayMainSection .xRayPieChart {
  top: 0;
  width: 42%;

  margin-left: 10px;
  margin-right: 10px
}

.xRayBottom .xRayMainSection {
  display: flex-nowrap;
  height: calc(100% - 60px);
  overflow: hidden;
}

.xRayMainSection .controls,
.xRayMainSection .xRayInfo {
  width: 20%;
  border: 1px solid #5a5a5a;
  padding: 0 15px;
}

.xRayMainSection .xRayMainChart {
  width: 60%;
  border: 1px solid #5a5a5a;
  border-right: none;
  border-left: none;
}

.sideArea {
  max-height: 100vh;
}

.seperator {
  margin: 5px 0;
}


.xRayInfoItem {
  width: 90%;
  margin: 5px auto;
  padding: 5px;
  border-radius: 5px;
}

.controlItem {
  margin-bottom: 15px;
}

.infoItem.buttons {
  display: flex;
  background: none;
}

.infoItem.buttons>a {
  padding: 0;
  margin: auto;
}

.titleBar>p {
  margin: 0;
}



.commentsList {
  position: absolute;
  top: 0;
}

.commentWrapper {
  position: relative;
}

.commentItem {
  position: absolute;
  width: 200px;
  background: #929292;
  min-height: 50px;
  overflow: hidden;
  border: 1px solid #cacaca;
  padding: 2px;
}

.displayNone {
  display: none;
}

.commentContent {
  padding-top: 20px;
  overflow-y: auto;
  max-height: 100px;
  font-size: 10px;
}

.commentContent::-webkit-scrollbar {
  width: 5px;
}

.commentContent::-webkit-scrollbar-track {
  background: #ddd;
}

.commentContent::-webkit-scrollbar-thumb {
  background: rgba(172, 172, 172, 0.5);
}

.xRayDataTransferSection:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.closeBtn {
  position: absolute;
  right: 2px;
  top: 1px;
  font-size: 16px;
  color: #ff5600;
  padding: 5px;
}

.closeBtn:hover {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
}