.tdaLegend {
  width: 100%;
  top: calc(100% - 93px);
  padding-left: 20px;
  position: absolute !important;
  /* background-color: #00e29f; */
  z-index: 7
}

.tdaLegendTitle {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 600;
  color: black;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
  width: 40px
}

.tdaLegendName {
  font-family: 'Poppins';
  font-size: 10px;
  font-weight: 700;
  color: black;
  padding-right: 4px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.tdaLegendRow {
  position: relative;
  width: 100%;
  height: 30px;
  /* border: 1px solid #00e29f20; */
  z-index: 2;
  display: flex;
  margin-top: auto;
  margin-bottom: auto
}

.tdaLegendItem {
  position: relative;
  display: flex;
  width: fit-content;
  min-width: 20px;
  height: 25px;
  z-index: 2;
  margin-right: 8px;
  background-color: white;
  border-radius: 5px;
  position: relative;
}

.tdaLegendItem:hover {
  overflow: visible;
  background: #b0b0b067 !important;
  box-shadow: 0 0 3px 1px #2c5c69;
  cursor: pointer
}

.tdaLegendItem.visible {
  border: 1.5px solid #00e29f;
}



.tdaLegendItem.hidden {
  border: 1.5px solid #ff5600;
  background: #17171749;
}

.tdaLegendItem.noData {
  border: 1.5px solid #b0b0b0;
  background: #17171749;
  cursor: not-allowed !important
}
.tdaLegendItem:hover .tdaLegendItem.visible:hover .tdaLegendItem.hidden {
  overflow: visible;
  background: #ffffffB3;
  border: 1.5px solid #00e29f;
  cursor: pointer
}

.tdaLegendSubItems {
  margin-right: 5px;
  font-size: 12px;
  color: black;
  width: fit-content;
  padding: 2px;
  cursor: pointer;
  border-radius: 2px;
  margin-top: auto;
  font-weight: 800;
  margin-bottom: auto
}

.legendItemContextDetails.show {
  display: block;
  justify-content: left;
  align-items: left;
}

.legendItemContextMenu {
  min-width: 130px;
  min-height: 100px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  padding: 4px 2px;
  width: 100%;
  height: fit-content;
  bottom: 25px;
  left: 0;
  display: none;
  z-index: 10000000;
  border-radius: 5px;
  border: 2px solid #2c5c69;
  font-size: 12px;
}

.legendItemContextMenu.show {
  display: block;
  justify-content: left;
  align-items: left;
}

/* .tdaLegendSubItems:hover {
  color: white;
  background-color: grey;
  color: #00e29f;
} */

.tdaDisplayToolbar {
  position: fixed;
  /* display: inline-block; */
  justify-content: space-around;
  transition: .35s ease-in-out;
  background: #2c5c6936;
  width: 35px;
  overflow: visible;
  height: calc(30%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #2c5c69;
  border-top: 1px solid #2c5c69;
  border-bottom: 1px solid #2c5c69;
  top: 100px;
  z-index: 100;
  right: -20px;
  /* padding:4px; */
  /* margin-right:2px */
}

.tdaDisplayToolbar .toolbarTitle {
  cursor: wait;
  position: abosolute;
  writing-mode: tb-rl;
  top: 50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%) rotate(-180deg);
  /* transform: translate(-50%, -50%); */
  font-size: 14px;
  /* line-height:20px; */
  font-weight: 600px;
  color: black
}

.toolbarButtons {
  display: normal;
  opacity: 0;
  pointer-events: none;
}

.tdaDisplayToolbar:hover {
  height: calc(50%);
  background: #ffffffB3;
  border-left: 2px solid #2c5c69;
  border-top: 2px solid #2c5c69;
  border-bottom: 2px solid #2c5c69;
  overflow: visible;
  width: 50px;
  padding-left: 5px;
  right: -20px;
  transition: .35s ease-in-out;
}

.tdaDisplayToolbar:hover .toolbarTitle {
  cursor: none;
  opacity: 0;
  transition: opacity .5s linear;
}

.tdaDisplayToolbar:hover .toolbarButtons {
  display: normal !important;
  opacity: 1;
  transition: opacity 1s linear;
  pointer-events: auto;
}

.plotNavigation {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 40px;
  width: 45px;
  text-align: center;
  justify-content: center;
}

.singleDirectionalPlot {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 215px;
  /* width:calc(20% - 40px); */
}

.singleDirectionalPlot .Offset {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 125px;
  width: 20%;
}

.singleDragPlot {
  /* overflow: hidden; */
  margin-left: 0px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 125px;
  z-index: 6;
  width: calc(40% - 40px);
  height: 100%;
}

.singleDragPlot .dragOnly {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 125px;
  width: calc(70% - 40px) !important;
}


.fullScreenPlot {
  position: absolute !important;
  background: rgba(255, 255, 255, 1);
  position: relative;
  margin: 10px;
  padding: 15px !important;
  top: -150px;
  left: 0px;
  transform: translate(0%, 0%);
  z-index: 15;
  width: calc(90vw - 70px);
  height: calc(100vh - 130px);
  border: 4px solid #3e3e3eD6;
  border-radius: 8px;
}

.displayTabOptions {
  left: 130px !important;
  width: calc(90vw - 190px) !important;
  padding: 18px !important;
  /* overflow-y: scroll; */

}

.supportChartGrid {
  overflow-y: auto;
  display: grid;
  /* grid-auto-flow: column; */
  column-gap: 1rem;
  row-gap: 2rem;
  padding-top: 10px;
  position: relative;
  /* width: 100%; */
  min-height: 600px;
  max-height: calc(100% - 8px);
  counter-reset: grid-items;
  /* margin: auto */
  justify-items: end;
  grid-template-columns: repeat(auto-fit, minmax(calc(max(300px, 90%/ 3)), 1fr));
  /* width: calc(100% - 20px); */
}

.supportChartItem {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 0;
  min-height: 70vh;
  max-height: 85vh;
  margin: auto;
  /* border: 2px solid #0a2d4779; */
  border-radius: 4px;
  counter-increment: grid-item;
}

.supportChartItem::before {
  content: counter(grid-item);
  visibility: hidden
}

.fullScreenPlot:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.supportChartItem:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.singleMudWeightPlot {
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;
  /* min-width:125px; */
  width: 28%
}

.singleWellboreDiagramPlot {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 140px;
  width: 32%
}

.singleFormationPlot {
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;
  min-width: 120px;
  width: 18%
}

.tdaViewer {
  justify-content: left;
  position: relative;
  border-radius: 4px;
  color: #000000;
  /* border: 1px solid rgba(0, 0, 0, 0.775);
  box-shadow: 0 0 3px 1px #2c5c69; */
  margin-top: 0px;
  background: white;
  padding-top: 5px;
  padding-bottom: 0px;
  min-height: 600px
}

.tdaSchematics {
  display: flex;
  justify-content: left;
  position: relative;
  margin-left: 30px;
  width: calc(100% - 10px)
}

.hydroSchematics {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: calc(100% - 10px)
}

.DepthRangeText {
  color: #000000;
  font-size: 20px;
  font-weight: 600
}

.casingPlot {
  position: absolute;
  margin-bottom: 30px;
  /* calc(100% - 60px); */
}

.casingOverlay {
  position: absolute;
  bottom: -60px;
  left: 30px;
  z-index: 30;
}

.modelSettingsModal {
  position: fixed;
  display: inline-block;
  z-index: 1050;
  top: 50%;
  width: fit-content;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 95%;
  max-height: calc(100vh - 135px);
  overflow: visible;
  border-radius: 5px;
  height: fit-content;
  min-height: 500px;
  padding: 18px;
  padding-bottom: 12px;
  background: #3e3e3eD6;
  flex-grow: 1;
}

.modelSettingsTabs {
  position: absolute;
  left: -50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  padding-right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  z-index: 10;
  background: #3e3e3eD6;
  list-style: none
}

.modelSettingsTabs li {
  width: fit-content;
}

.modelSettingsTabs li span {
  display: none;
  position: absolute;
  right: 60px;
  transform: translateY(40%);
  width: max-content;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
  align-self: center;
  border-radius: 8px;
  overflow: auto;
  font-size: 14px;
}

.modelSettingsTabs li:hover span {
  display: inline-flex;
  color: #fff;
}

.modelSettingsModal .CloseBtn {
  position: absolute;
  right: 1px;
  top: -4px;
  font-size: 16px;
  color: #ff5600;
  padding: 5px;
  display: none;
}

.modelSettingsModal:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.modelSettingsModal .modelSettingsModalHeader {
  position: relative;
  display: flex;
  min-height: 140px;
  width: 100%;
  margin-bottom: 28px;
  color: black;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  /* padding-right: 5px; */
}

.modelSettingsModalBody {
  position: relative;
  height: calc(100% - 5px);
  min-height: 40vh;
  width: 800px;
  max-width: calc(100% - 12px);
  line-height: 1.3;
  /* padding: 0px; */
  color: black;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  border: 4px solid #00e29f00;
  margin-bottom: 5px;
  max-height: calc(60vh - 135px);
  overflow: auto;
}

.newModel {
  border: 4px solid #00e29f
}

.modalSettingsTabTitle {
  position: relative;
  display: flex;
  left: 12px;
  top: -5px;
  color: white;
  width: calc(100% - 30px);
  justify-content: space-between;
  font-size: 0.8125rem;
  line-height: 1.0em;
  font-weight: 600 !important;
  max-width: 400px;
}

.modalSettingsChangeDetected {
  right: 30px;
  top: 0px;
  color: #00e29f;
  font-size: 0.8125rem;
  line-height: 1.0em;
  font-weight: 800 !important
}

.modalTitle {
  width: max-content !important;
  font-size: 0.925rem !important;
  top: -5px;

}

.modelSettingsModalBody::-webkit-scrollbar {
  width: 10px;
  display: inline;
}

.modelSettingsModalBody::-webkit-scrollbar-thumb {
  background: rgb(31, 31, 31);
  border-radius: 10px;
}

.modelSettingsModalBody::-webkit-scrollbar-track-piece,
.modelSettingsModalBody::-webkit-scrollbar-track {
  background-color: transparent;
  width: 10px;
}

.modalSettingsContent {
  min-width: 90%;
  min-height: 90%;
}

.modelSettingsControls {
  position: absolute;
  display: block;
  color: black !important;
  /* background: #fffffff2 !important; */
  overflow: hidden;
  width: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  /* padding: 5px; */
  bottom: 0px;
  z-index: 40;
}


.openControls {
  background: rgba(202, 202, 202, 0.85);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 2px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: black !important;
  visibility: visible;
  overflow: visible;
  transform-origin: bottom;
  height: fit-content;
  transform: scaleY(1);
  /* min-height: 90%; */
  /* z-index: 6; */
}

/* 
.modelSettingsControls.openControls {
  bottom: 0px;
  overflow: visible;
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.26s ease-in-out;
  max-height: 95%;
  height: fit-content;
  min-height: 300px;
  width: 100%;
  border: 2px solid #0a2d47D6;
  z-index: 40;
} */



.modelSettingsControls:hover>.CloseBtn {
  display: block;
  cursor: pointer
}



.chartBubbleDetails {
  font-size: 30px;
  position: absolute;
  top: 45px;
  width: 30px;
  padding: 0 2px;
  background: #d3d3d300;
  margin-left: 5px;
  display: grid;
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-flow: row;
  z-index: 7
}

.memoContainer {
  height: 6.6%;
  display: flex;
  align-items: center;
}

.chunkBubbleItem {
  font-size: 20px;
  width: 27px;
  border-radius: 50px;
  line-height: 25px;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  background-color: #2c5c6900 !important;
  text-align: center;
  position: relative;
}

.chunkBubbleCount {
  font-size: 12px;
  font-weight: 700;
  color: #2c5c69;
  position: absolute;
  left: 10px;
  top: 6px;
}

.popOverBody {
  color: black;
}


.pressureLossContainer {
  display: grid;
  grid-template-columns: 45px 1.3fr repeat(2, 1fr);
  grid-auto-columns: minmax(200px, auto);
  grid-auto-flow: column;
  max-width: 60%;
  position: relative;
  color: black;
  padding-top: 10px;
  margin-bottom: 30px;
  margin-right: 25px;
}


.swabSurgeContainer {
  display: grid;
  grid-template-columns: 45px 4fr repeat(1, 1fr);
  grid-auto-columns: minmax(200px, auto);
  grid-auto-flow: column;
  max-width: 60%;
  position: relative;
  color: black;
  padding-top: 10px;
  margin-bottom: 30px;
  margin-right: 15px;
}

.holeCleaningContainer {
  display: grid;
  grid-template-columns: 45px 2fr repeat(1, 1fr);
  grid-auto-columns: minmax(200px, auto);
  grid-auto-flow: column;
  max-width: 60%;
  position: relative;
  color: black;
  padding-top: 10px;
  margin-bottom: 30px;
  margin-right: 15px;
}

.tdaLegendItem:hover {
  overflow: visible;
  background: #b0b0b067 !important;
  box-shadow: 0 0 3px 1px #2c5c69;
  cursor: pointer
}