.crossPlotContainer {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
  padding-top: 10px;
  position: relative;
  /* width: 100%; */
  min-height: 260px;
  /* margin: auto */
  margin-left: 110px;
  justify-items: end;
  counter-reset: grid-items;
  width: calc(100% - 130px);
}

.rigRankPlotContainer {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  /* grid-template-rows: repeat(auto-fit, minmax(500px, 1fr)); */
  column-gap: 1rem;
  row-gap: 2rem;
  padding-top: 10px;
  position: relative;
  /* width: 100%; */
  min-height: 470px;
  /* margin: auto */
  margin-left: 110px;
  justify-items: center;
  counter-reset: grid-items;
  width: calc(100% - 130px);
}

.kpiPlotContainer {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
  padding-top: 10px;
  position: relative;
  /* width: 100%; */
  min-height: 260px;
  /* margin: auto */
  margin-left: 110px;
  justify-items: center;
  counter-reset: grid-items;
  width: calc(100% - 130px);
}

.heatMapContainer {
  display: grid;
  /* grid-auto-flow: column; */
  column-gap: 1rem;
  row-gap: 2rem;
  padding-top: 10px;
  position: relative;
  /* width: 100%; */
  min-height: 560px;
  counter-reset: grid-items;
  /* margin: auto */
  justify-items: end;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  width: calc(100% - 20px);
}

.heatMapItem {
  /* position: relative; */
  width: 100%;
  max-width: 500px;
  min-width: 0;
  /* margin: auto; */
  border: 2px solid #0a2d4779;
  border-radius: 4px;
  counter-increment: grid-item;
}

.expanded {
  z-index: 12;
  position: absolute;
  background: white;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 580px;
  max-width: 580px;
  height: 500px;
}

.heatMapItem::before {
  content: counter(grid-item);
  color: transparent;
  display: none
}

.heatMapItem:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.gridItemControls {
  position: absolute;
  width: 100%;
  min-width: 230px;
  /* height: 10px; */
  /* left: -15px; */
  bottom: -5px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  z-index: 16;
}


.gridItemControls:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.openControls {
  background: rgba(202, 202, 202, 0.85);
  border-radius: 8px;
  border: 1px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: black !important;
  visibility: visible;
  overflow: visible;
  transform-origin: bottom;
  height: fit-content;
  transform: scaleY(1);
  /* min-height: 90%; */

}

.darkModeOpenControls {
  background: rgba(51, 51, 51, 0.45);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: white !important;
  visibility: visible;
  overflow: visible;
  transform-origin: bottom;
  height: fit-content;
  transform: scaleY(1);
  /* min-height: 90%; */

}

.gridChartControlButton {
  text-align: center;
  display: block;
  position: absolute;
  left: 10px;
  bottom: -2px;
  z-index: 5
}

.topGridControlChartButton {
  bottom: unset;
  top: -2px;
  left: 4px
}

.heatMapYSelect {
  position: absolute;
  width: 60px;
  height: 50px;
  left: 5px;
  top: 2px;
}

.heatMapXSelect {
  position: absolute;
  width: 60px;
  height: 50px;
  right: 5px;
  bottom: 2px;
}

.heatMapColorSelect {
  position: absolute;
  width: 75px;
  height: 50px;
  right: 5px;
  top: 2px;
}

.heatMapYaxisSelect {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  background-color: #6e6e6e;
  padding-top: 10px;
  position: relative;
  width: calc(100% - 250px);
  z-index: 20;
}



.paramSelectControls {
  position: absolute;
  height: 0px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.26s ease;
  border-radius: 5px;
  padding: 5px;
  bottom: 0px;

}

.paramSelectControls.openControls {
  position: absolute;
  display: block;
  color: black !important;
  width: 310px;
  background: #ffffffd6 !important;
  overflow: visible;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.26s ease;
  height: fit-content;
  border: 2px solid #00e29fD6;
  border-bottom: 4px solid #00e29fD6;
  z-index: 10;
  top: 0px;
}

.paramSelectControls.openRight {
  left: 0px;
  padding-left: 65px
}

.paramSelectControls.openLeft {
  right: 0px;
  padding-right: 65px
}

.paramSelectControls:hover>.CloseBtn {
  display: block;
  cursor: pointer
}