.weeklyUpdateTitleContainer {
  display: flex;
  position: relative;
  min-height: 200px;
  margin-top: 45px;
  background: #0a2d47D6;
  color: white !important;
  padding-top: 5px;
  padding-bottom: 8px;
}

.weeklyUpdateChartContainer {
  width: 1500px;
  display: flex;
  position: relative;
  min-height: 350px;
  margin-top: 35px;
  background: white;
  color: #0a2d47;
  font-size: 26px;
  padding-top: 5px;
  padding-bottom: 0px;
  justify-content: space-around;
}

.weeklyUpdateFeatureContainer {
  position: relative;
  min-height: 200px;
  height: fit-content;
  margin-top: 15px;
  margin-left: 40px;
  background: white;
  padding-top: 5px;
  padding-bottom: 0px;
}

.weeklyUpdateFeatureContainer .featureTitle {
  color: #2c5c69;
  font-size: 42px;
  font-weight: bolder;
  margin-top: 10px
}

.weeklyUpdateFeatureContainer .featureHeader {
  color: #373737;
  font-size: 28px;
  font-weight: bold;
  padding-left: 60px;
  line-height: 120%;
  margin-top: 5px
}

.newFeatureShadow {
  color: #0a2d47;
  text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 5px #00e29f, 0 0 10px #00e29f, 0 0 20px #00e29f, 0 0 40px #00e29f;
}

.weeklyUpdateFeatureContainer .featureDetail {
  color: #373737;
  font-size: 22px;
  font-weight: bold;
  line-height: 150%;
  padding-left: 120px;
}

.weeklyUpdateFeatureContainer .featuresSubDetail {
  color: #2c5c69;
  font-size: 20px;
  font-weight: 400;
  padding-left: 160px;
}

.weeklyUpdateBottomContainer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  min-height: 160px;
  margin-top: 5px;
  background: #737373;
  color: white;
  font-size: 26px;
  padding-top: 5px;
  padding-bottom: 0px;
}

.reportLogo {
  height: 200px;
  width: 200px;
}

.reportTitleContainer {
  height: 200px;
  width: calc(100% - 400px);
}

.reportHeaderText {
  align-self: center;
  font-weight: 800;
  font-size: 80px;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  text-align: left !important;
  margin-left: 4px;
  margin-top: 4px;
}

.reportSubTitleText {
  align-self: center;
  font-weight: 400;
  font-size: 40px;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  text-align: left !important;
  margin-left: 4px;
  margin-top: -14px;
}

.reportDetailTitle {
  align-self: center;
  font-weight: 600;
  font-size: 24px;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  justify-content: center;
  margin-left: auto;
  margin-top: -14px;
}

.secondaryTitle {
  margin-top: -5px !important;
  color: #0a2d47 !important;
}

.tertiaryTitle {
  font-size: 16px;
  margin-top: -5px !important;
  color: #0a2d47 !important;
}

.reportDetailValue {
  align-self: center;
  font-weight: 800;
  font-size: 50px !important;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: #00e29f;
  justify-content: center;
  margin-left: auto;
  /* margin-top: 4px; */
}

.reportDetailNote {
  align-self: center;
  font-weight: 500;
  font-size: 16px !important;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  justify-content: center;
  margin: auto !important;
  padding-top: 10px;
  /* padding-bottom: 5px */
}


.mcStatsContainer {
  display: flex;
}

.mcMetricsContainer {
  position: relative;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  min-height: 70px;
  height: 30%;
  width: 65%;
  overflow-wrap: wrap;
}

.mcPlotContainer {
  position: relative;
  min-height: 300px;
  width: 55%;
  margin-top: 20px;
  margin: auto;
}

.mcWellNavigation {
  position: relative;
  min-height: 600px;
}

.mcWellNavigation .wellList {
  position: relative;
  width: 75%;
}

.mcWellNavigation .clickControls {
  position: relative;
  width: 25%;
}

.mcDisplaySection {
  position: relative;
  min-height: 600px;
}

.mcDisplaySectionControls {
  top: 0;
  position: absolute;
  display: flex;
  z-index: 4;
}


.mcTopStats {
  position: relative;
  width: 50%;
}

.mcActivityChart {
  position: relative;
  width: 50%;
}


.mcLiveParamContainer {
  justify-content: space-between;
  display: flex;
  overflow-wrap: wrap;
  width: 100%;
}

.mcEventDetailsContainer {
  position: relative;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

.mcWSParamContainer {
  min-width: 500px;
  box-shadow: 0 0 2px 0px #2c5c69;
  border-radius: 4px;
  margin-left: 3px;
}

.mcWSParamSafetyContainer {
  width: 74%;
  min-width: 500px;
  margin-left: 3px;
}

.mcWSSafetyTableContainer {
  position: relative;
  min-width: 500px;
  margin-left: 3px;
}

.mcCardContainer {
  position: relative;
  width: 45%;
  min-width: 400px;
  /* max-width: 500px; */
  min-height: 100px;
}

.mcChartContainer {
  margin: auto;
  position: relative;
  min-width: 250px;
}

.mcSummaryContainer {
  position: relative;
  width: 54%;
  min-width: 250px;
  min-height: 10px;
  padding-bottom: 10px;
  margin-left: 4px;
  top: 0px;
}

.mcAFEContainer {
  position: relative;
  width: 30%;
  min-width: 250px;
  max-width: 500px;
  min-height: 10px;
}

.mcAFEChartContainer {
  margin: auto;
  position: relative;
}

.mcCardTitle {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}




.SummarySection .SummaryCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 4;
  padding: 5px;
  display: none;
}

.SummarySection:hover>.SummaryCloseBtn {
  display: block;
  cursor: pointer
}

.SummarySection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.SummarySection .wellName {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellStatusBar {
  width: 100%;
  margin-right: 0px;
  border-bottom: 1px solid #0a2d47;
}

.wellStatusMetric {
  display: flex;
  flex: no-wrap;

  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
}

.wellStatusMetricLabel {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 450;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.printSummaryMetric {
  display: flex;
  flex: no-wrap;
  align-self: center;
  width: 30%;
  justify-content: space-between;
  margin-left: 3px;
  margin-right: 3px;
}




.mcParamChunkContainer {
  margin: auto;
  margin-top:0px;
  position: relative;
  min-height: 100px;
  max-width: 460px;
  min-width: 250px;
  width: 25%;
  box-shadow: 0 0 2px 0px #2c5c69;
  border-radius: 4px;
}

.ParamChunkCard {
  max-width: 300px;
  width: 20%;
  min-width: 200px;
  z-index: 1;
  justify-content: center;
  text-align: center;
}

.ParamDetails .ParamSection {
  position: relative;
  min-width: 250px;
  background: #cfcece65;
  margin: auto;
  max-height: 550px;
  z-index: 1;
  border: 2px solid #2c5c69;
  box-shadow: 0 0 2px 1px #00e29f;
  border-radius: 4px;
}


.mcParamRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-left: 4px;
  margin-right: 4px;
  justify-content: space-between;
}

.mcParamMetricLabel {
  font-family: inherit;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  /* margin-bottom: 0.15rem; */
  text-align: center !important;
  color: black
}

.mcParamMetricValue {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 20px;
  margin-left: 6px;
  margin-right: 6px;
  /* margin-bottom: 0.15rem; */
  text-align: center !important;
  color: #2c5c69
}


.McDayDepthContainer {
  position: relative;
  max-height: 530px;
  margin-top: 2px;
  margin-left: 3px;
  min-height: 70px;
  width: 64%;
  color: black;
  overflow-wrap: wrap;
}




/* .printSectionHeader{
  justify-content:center;
} */