.white-content {

  .ReactTable .-pagination input,
  .ReactTable .-pagination select,
  .ReactTable .rt-thead.-filters input,
  .ReactTable .rt-thead.-filters select {
    color: $black;
  }

  .ReactTable .rt-td,
  .ReactTable .rt-thead .rt-th {
    color: rgba(34, 42, 66, 1) !important;
  }

  .ReactTable .rt-tbody .rt-tr {
    color: rgba(34, 42, 66, 1) !important;
    font-weight: 500
  }

  .ReactTable .rt-thead.-filters .rt-tr {
    border-color: rgba(34, 42, 66, 0.2);
  }

  .ReactTable.-striped .rt-tr.-odd {
    background-color: rgba(0, 0, 0, 0.11);
  }

  .rbc-today {
    background: darken($light-bg, 10%);
  }

  .rbc-day-bg+.rbc-day-bg,
  .rbc-month-row,
  .rbc-time-content>*+*>*,
  .rbc-timeslot-group,
  .rbc-row.rbc-time-header-cell,
  .rbc-time-header-content,
  .rbc-day-slot .rbc-time-slot+.rbc-time-slot,
  .rbc-agenda-view table.rbc-agenda-table,
  .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td,
  .rbc-agenda-view table.rbc-agenda-table tbody>tr+tr,
  .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
    border-color: #9499a7;
  }

  .rbc-agenda-time-cell,
  .rbc-agenda-date-cell,
  .rbc-date-cell>a,
  .rbc-date-cell>a:active,
  .rbc-date-cell>a:visited,
  .rbc-agenda-event-cell {
    color: $black;
  }

  .rbc-toolbar .rbc-toolbar-label {
    color: $black-states;
  }

  .rbc-header,
  .rbc-header>a,
  .rbc-header>a:active,
  .rbc-header>a:visited,
  .rbc-header>a:hover,
  .rbc-time-gutter.rbc-time-column {
    color: $default;
  }

  .card {
    .map-title {
      color: $black-states;
    }
  }

  .nav-pills .nav-item .nav-link:not(.active) {
    background: #9a9a9a;
    color: #1d253b;
  }

  .form-group:not(.has-danger):not(.has-success),
  .input-group-focus:not(.has-danger):not(.has-success) {

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $info;
    }

    .form-control {
      &:focus {
        border-color: $info;

        &+.input-group-append .input-group-text,
        &~.input-group-append .input-group-text,
        &+.input-group-prepend .input-group-text,
        &~.input-group-prepend .input-group-text {
          border: 1px solid $info;
        }
      }
    }
  }

  .form-group.has-danger,
  .input-group-focus.has-danger {

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      color: $warning-states;
    }

    .form-control {
      &:focus {
        color: $warning-states;

        &+.input-group-append .input-group-text,
        &~.input-group-append .input-group-text,
        &+.input-group-prepend .input-group-text,
        &~.input-group-prepend .input-group-text {
          border: 1px solid $danger;
        }
      }
    }
  }

  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    padding-left: 15px;
    width: 80px;
  }

  .card-collapse {
    .card {
      margin-bottom: 0px;
      background: $transparent-bg;
      box-shadow: none;

      .card-header {
        position: relative;
        padding: 1rem 0.75rem;

        a[data-toggle="collapse"] {
          display: block;
          color: rgba(black, 0.6);

          i {
            float: right;
            position: relative;
            color: $black;
            top: 1px;
            @extend .animation-transition-general;
          }
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: rgba($white, 0.1);
        }
      }

      .card-body {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        color: rgba($white, 0.5);
      }

      &:last-child {
        .card-header:after {
          content: none;
        }
      }
    }
  }
}