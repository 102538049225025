.KPILayoutNav {
  contain: content;
  display: flex;
  align-self: center;
  margin-top: 6px;
  margin-bottom: 4px
}

.KPIFilterContainer {
  position: relative;
  width: 100%;
  border-radius: 5px;
  color: black;
  background-color: white;
  border: 2px solid #2c5c69 !important;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 10px
}

.KPIFilterContainer .label {
  font-size: 0.75rem;
  margin-bottom: .1rem !important;
}

.KPICollapseControl {
  position: absolute;
  top: 15px;
  right: 5px;
}

.KPIFilterHeader {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 5px;
  /* height: fit-content */
  /* border: 2px solid #2c5c69 !important; */
}

.KPIFilterTitle {
  position: relative;
  width: fit-content;
  float: left;
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45em;
  margin-Left: 10px;
  align-self: center;
}

.KPIFilterName {
  position: relative;
  width: calc(100% - 600px);
  justify-content: center;
  text-align: center;
  color: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.65em;
  margin-top: 7px;
  align-self: center;
}

.disabledButton {
  width: fit-content
}

.overlayTitle {
  display: flex;
  width: 100%;
  color: var(--white);
  font-size: 1.1125rem;
  margin-top: -10px;
  line-height: 1.3em;
}

.filterSectionTitle {
  display: flex;
  width: fit-content;
  color: var(--gray);
  font-size: 0.9125rem;
  margin-top: .1rem;
  line-height: 1.3em;
  font-weight: 700 !important
}

.editModalTitle {
  margin-left: 8px;
  text-Align: left
}

.controlSectionTitle {
  display: flex;
  width: 100%;
  color: var(--black);
  font-size: 0.8125rem;
  margin-top: .1rem;
  line-height: 1.2em;
  font-weight: 600 !important
}

.checkBoxSelection {
  display: flex;
  width: 100%;
  color: var(--black);
  font-size: 0.7525rem;
  margin-top: .05rem;
  line-height: 1.1em;
  font-weight: 700 !important
}

.kpiBarChart {
  position: relative;
}

.KPIPlotControlsTitle {
  position: absolute;
  top: 1px;
  right: 180px;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.KPIPlotControlsContainer {
  padding-top: 15px;
  top: -1px;
  right: -1px;
  justify-content: right;
  align-self: center;
  margin-left: auto;
  display: flex;
  width: 400px;
  position: relative;
  overflow-x: hidden;
  border-radius: 5px;
  overflow-y: hidden;
  border: 2px solid #2c5c6900 !important;
}

.KPIPlotControlsContainer:hover {
  box-shadow: 0 0 9px 0px #2c5c69;
  border: 2px solid #2c5c69 !important;
}

.KPIFBarContainer {
  position: relative;
  border-radius: 5px;
  color: black;
  border: 2px solid #2c5c69 !important;
  margin-bottom: 10px;
  padding-right: 5px;

}

.KPIGraphTitle {
  display: flex;
  width: 100%;
  color: black;
  text-align: center;
  justify-content: center;
  font-size: 1.1375rem;
  line-height: 1.2em;
  font-weight: 600 !important
}

.KPIGraphMetric {
  position: relative;
  display: flex;
  width: 100%;
  color: black;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.0em;
  font-weight: 600 !important
}

/* .KPIPerformanceFilterBox{
min-width: 180px;
width:200px;
margin-left:15px;
margin-right:15px;
} */

.viewSettingsBox {
  min-width: 280px;
  width: 300px;
  margin-left: 15px;
  margin-right: 15px;
}


.kpiGroupIcon {
  position: absolute;
  width: 30px;
  right: 30px;
  top: 5%;
  z-index: 1004;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.kpiGroupDetails {
  position: absolute;
  min-width: 280px;
  max-height: 400px;
  top: -1px;
  right: -1px;
  z-index: 2004;
  justify-content: center;
  text-align: center;

}

@media print {
  .chartjs-size-monitor {
    display: none !important;
  }
}

.kpiGroupDetails .kpiGroupSection {
  min-width: 350px;
  width: fit-content;
  background: #FFFFFFD6;
  overflow: visible;
  max-height: 400px;
  z-index: 12004;
  border-top: 1px solid #00e29f;
  box-shadow: 0 0 2px 0px #2c5c69;
  border: 2px solid #2c5c69 !important;
  border-radius: 5px;
}

.kpiGroupSection .groupname {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 1px;
  min-width: 75px;
  align-self: center;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.kpiGroupSection .wellHealthCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  padding: 5px;
  display: none;
}

.kpiGroupSection:hover>.wellHealthCloseBtn {
  display: block;
  cursor: pointer
}

.kpiGroupRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-top: 1px;
  margin-left: 8px;
  margin-right: 15px;
  justify-content: space-between;
}

.kpiGroupMetricLabel {
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.kpiGroupMetricName {
  display: flex;
  flex: no-wrap;
  width: 32%;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
}

.kpiGroupMetricCount {
  display: flex;
  flex: no-wrap;
  width: 22%;
  align-self: center;
  text-align: center !important;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
}

.kpiGroupMetricAverage {
  display: flex;
  flex: no-wrap;
  width: 24%;
  align-self: center;
  text-align: center !important;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
}

.kpiGroupMetricMax {
  display: flex;
  flex: no-wrap;
  width: 22%;
  text-align: center !important;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
}