.mcMainContent {
  display: flex;
}

.morningCallContainer {
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.775);
  /* box-shadow: 0 0 3px 1px #2c5c69; */
  margin-top: 5px;
  background: white;
  padding-top: 5px;
  padding-bottom: 10px;
}

.wsMorningCallContainer {
  position: relative;
  border-radius: 4px;
  min-height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.775);
  /* box-shadow: 0 0 3px 1px #2c5c69; */
  background: white;
  padding-top: 5px;
  padding-bottom: 0px;
}

.mcWSBUNavigation {
  align-self: center;
  position: relative;
  height: 50px;
  max-height: 80px;
  min-width: 500px;
  color: white;
  width: 35%;
  display: flex;
  border-radius: 4px;
  margin: auto;
  align-self: center
}

.mcWSBUNavigation .buName {
  align-self: center;
  position: relative;
  margin: 2px;
  min-width: 155px;
  width: 50%;
  margin-left: 4px;
}

.mcWSBUNavigation .selectedDate {
  align-self: center;
  position: relative;
  margin: 2px;
  margin-left: 12px;
  min-width: 75px;
  width: 30%;
  align-self: center;
}

.mcWSBUNavigation .buName {
  align-self: center;
  position: relative;
  margin: 2px;
  min-width: 155px;
  width: 50%;
  margin-left: 4px;
}

.mcBUNavigation {
  align-self: center;
  position: relative;
  height: 50px;
  max-height: 80px;
  min-width: 300px;
  max-width: 345px;
  background-color: #2c5c69d6 !important;
  color: white;
  width: 35%;
  display: flex;
  border-radius: 4px;
  margin-right: auto;
  align-self: center
}

.mcBUNavigation .itemDetailLabel {
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  text-align: left !important;
  margin-left: 4px;
  margin-top: 4px;
}

.mcBUNavigation .itemDetailText {
  align-self: center;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  text-align: left !important;
  margin-left: 4px;
  cursor: default
}

.mcBUNavigation .buName {
  align-self: center;
  position: relative;
  margin: 2px;
  min-width: 125px;
  width: 40%;
  margin-left: 4px;
}

.mcBUNavigation .selectedDate {
  float: right;
  align-self: center;
  position: relative;
  margin: auto;
  min-width: 75px;
  width: 30%;
  align-self: center;
}

.mcStatsContainer {
  display: flex;
}

.mcMetricsContainer {
  position: relative;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
  min-height: 70px;
  height: 30%;
  width: 65%;
  overflow-wrap: wrap;
}

.mcPlotContainer {
  position: relative;
  min-height: 300px;
  width: 55%;
  margin-top: 20px;
  margin: auto;
}

.mcWellNavigation {
  position: relative;
  min-height: 600px;
}

.mcWellNavigation .wellList {
  position: relative;
  width: 75%;
}

.mcWellNavigation .clickControls {
  position: relative;
  width: 25%;
}

.mcDisplaySection {
  position: relative;
  min-height: 600px;
}

.mcDisplaySectionControls {
  top: 0;
  position: absolute;
  display: flex;
  z-index: 4;
}

.rightArea {
  width: 45%;
}

.rightAreaMapOffset {
  width: 65%;
}



.mcTopStats {
  position: relative;
  width: 50%;
}

.mcActivityChart {
  position: relative;
  width: 50%;
}


.mcLiveParamContainer {
  justify-content: space-between;
  display: flex;
  overflow-wrap: wrap;
  width: 100%;
}

.mcEventDetailsContainer {
  position: relative;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;

}

.mcEventDetailsContainer .phaseBreakdownContainer {
  position: absolute;
  height: 20%;
  width: 280px;
  right: -280px;
  min-height: 250px;
  min-width: 180px;
  max-width: 280px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.775);
  margin-top: 5px;
  background: white;
  z-index: 8;
  padding-top: 5px;
  padding-bottom: 8px;
}

.phaseBreakdownContainer.below {
  left: 90% !important;
  top: 990px !important;
  right: unset;
}

.phaseBreakdownContainer.belowPrint {
  left: 90% !important;
  top: 910px !important;
  right: unset;
}

.phaseBreakdownContainer.belowPrintTall {
  left: 90% !important;
  top: 830px !important;
  right: unset;
}



.PhaseCloseButton {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  /* z-index: 40; */
  padding: 5px;
  display: none;
  cursor: pointer;
  z-index: 20
}

.phaseBreakdownContainer:hover>.PhaseCloseButton {
  display: block;
  cursor: pointer
}

.mcEventDetailsContainer .nptTableContainer {
  position: absolute;
  height: fit-content;
  left: 70px;
  bottom: 170px;
  background: white;
  min-width: 300px;
  z-index: 8;
  padding-top: 5px;
  padding-bottom: 8px;
}

.nptTableContainer:hover>.PhaseCloseButton {
  display: block;
  cursor: pointer
}

.nptTableContainer.below {
  left: 10% !important;
  top: 990px !important;
  bottom: unset;
}

.nptTableContainer.belowPrintTall {
  left: 10% !important;
  top: 830px !important;
  bottom: unset;
}

.nptTableContainer.belowPrint {
  left: 10% !important;
  top: 910px !important;
  bottom: unset;
}

.mcEventDetailsContainer .phaseTableContainer {
  position: absolute;
  height: fit-content;
  margin-right: 10px;
  right: -302px;
  bottom: 100px;
  /* background: white; */
  color: black !important;
  min-width: 200px;
  z-index: 8;
  padding-top: 5px;
  padding-bottom: 8px;
}

.mcEventDetailsContainer .secondaryCostChart {
  position: absolute;
  height: fit-content;
  margin-right: 10px;
  right: -302px;
  top: 0px;
  /* background: white; */
  color: black !important;
  min-width: 200px;
  z-index: 8;
  padding-left: 15px;
  padding-bottom: 8px;
}

.phaseTableContainer:hover>.PhaseCloseButton {
  display: block;
  cursor: pointer
}

.phaseTableContainer.below {
  left: 50% !important;
  top: 990px !important;
  right: unset;
}

.phaseTableContainer.belowPrintTall {
  left: 50% !important;
  top: 830px !important;
  right: unset;
}

.phaseTableContainer.belowPrint {
  left: 50% !important;
  top: 910px !important;
  right: unset;
}

.mcWSParamContainer {
  min-width: 500px;
  /* box-shadow: 0 0 2px 0px #2c5c69; */
  border-radius: 4px;
  margin-left: 3px;
}

.mcWSParamSafetyContainer {
  width: 74%;
  min-width: 500px;
  margin-left: 3px;
}

.mcWSSafetyTableContainer {
  min-width: 500px;
  margin-left: 3px;
}

.mcCardContainer {
  position: relative;
  width: 45%;
  min-width: 400px;
  /* max-width: 500px; */
  min-height: 100px;
}

.mcChartContainer {
  margin: auto;
  position: relative;
  min-width: 250px;
}

.mcSummaryContainer {
  position: relative;
  width: 54%;
  min-width: 250px;
  min-height: 10px;
  padding-bottom: 10px;
  margin-left: 4px;
  top: 0px;
}

.mcAFEContainer {
  position: relative;
  width: 30%;
  min-width: 250px;
  max-width: 500px;
  min-height: 10px;
}

.mcAFEChartContainer {
  margin: auto;
  position: relative;
}

.mcCardTitle {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 60px;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.AfeIcon {
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.AfeCard {
  max-width: 700px;
  width: 40%;
  min-width: 250px;
  justify-content: center;
  text-align: center;
}

.Afe .AfeSection {
  position: relative;
  min-width: 350px;
  background: #FFFFFFD6;
  max-height: 400px;
  border-top: 1px solid #00e29f;
  border-bottom: 1px solid #2c5c69;
  border-radius: 4px;
}

.AfeSection .AfeCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 4;
  padding: 5px;
  display: none;
}

.AfeSection:hover>.AfeCloseBtn {
  display: block;
  cursor: pointer
}

.AfeSection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.AfeSection .wellName {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.Summary .SummarySection {
  position: relative;
  background: #FFFFFFD6;
  max-height: 400px;
  z-index: 4;
  border: 1px solid #00e29f;
  /* border-top: 2px solid #00e29f; */
  /* box-shadow: 0 0 2px 0px #2c5c69; */
  border-radius: 4px;
  overflow-y:hidden;
}

.Summary .CompleteSummarySection {
  position: relative;
  background: #FFFFFFD6;
  max-height: 400px;
  z-index: 4;
  border: 1px solid #ff5600;
  /* box-shadow: 0 0 2px 0px #2c5c69; */
  border-radius: 4px;
}

.SummarySection .SummaryCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 4;
  padding: 5px;
  display: none;
}

.SummarySection:hover>.SummaryCloseBtn {
  display: block;
  cursor: pointer
}

.SummarySection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.SummarySection .wellName {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellStatusBar {
  width: 100%;
  margin-right: 0px;
  border-bottom: 1px solid #0a2d47;
}

.wellStatusRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 15px;
  justify-content: space-between;
}

.wellStatusMetric {
  display: flex;
  flex: no-wrap;

  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
}

.wellStatusMetricLabel {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 450;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.printSummaryMetric {
  display: flex;
  flex: no-wrap;
  align-self: center;
  width: 30%;
  justify-content: space-between;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
}

.printSummaryMetric:hover>.goalEditButton {
  border-radius: 4px;
  color: black;
  left: -50px !important;
  width: 55px;
  top: -5px;
}

.printSummaryMetricLabel {
  font-family: inherit;
  line-height: 1.1;
  text-transform: uppercase;
  align-self: center;
  font-weight: 400;
  font-size: 0.925rem;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.05rem;
  text-align: center !important;
  color: #323232
}

.printSummaryMetricPct {
  font-family: inherit;
  line-height: 1.1;
  text-transform: uppercase;
  align-self: center;
  font-weight: 400;
  font-size: 0.825rem;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.morningCallControl {
  align-self: center;
  text-align: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 500;
  color: black;
  width: 35px;
  height: 45px;
  cursor: pointer;
  border-radius: 5px;
  border: 3px solid #2c5c69;
  background-color: #eeeeeeb4;
}

.morningCallControl:hover {
  background-color: #00e29fD6;
  border-color: #00e29f;
  border: 3px solid #2c5c69;
  color: #2c5c69;
}

.morningCallSummaryText {
  font-family: inherit;
  line-height: 1.3;
  padding-bottom: 5px;
  align-self: center;
  font-weight: 600;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 2px;
  /* text-align: center !important; */
  color: #323232
}

.morningCallSummaryLabel {
  font-family: inherit;
  line-height: 1.3;
  padding-bottom: 4px;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  /* text-align: center !important; */
  color: #323232;
  max-height: 100px; /* Set the maximum height */
  overflow: hidden; /* Hide the overflow content */
  position: relative; /* Position relative for the fade effect */
  transition: max-height 0.3s ease; /* Smooth transition for height change */
}


.morningCallSummaryLabel:hover {
  transition: max-height 0.3s ease;
  max-height: 1000px;  /* Remove the max height on hover */
}

.morningCallSummaryLabel::after {
  content: ''; /* Add an empty content for the pseudo-element */
  position: absolute; /* Position absolute to the parent */
  bottom: 0; /* Align to the bottom */
  left: 0; /* Align to the left */
  width: 100%; /* Full width */
  height: 8px; /* Height of the fade effect */
  background: linear-gradient(to bottom, transparent, white); /* Fading effect */
  pointer-events: none; /* Allow clicks to pass through */
  transition: opacity 0.5s ease; /* Smooth transition for opacity change */
}

.morningCallSummaryLabel:hover::after {
  opacity: 0; /* Hide the fade effect on hover */
}

.mcParamChunkContainer {
  margin: auto;
  margin-top:0px;
  position: relative;
  min-height: 100px;
  max-width: 460px;
  min-width: 250px;
  width: 25%;
  border-radius: 4px;
}

.ParamChunkCard {
  max-width: 300px;
  width: 20%;
  min-width: 200px;
  z-index: 1;
  justify-content: center;
  text-align: center;
}

.ParamDetails .ParamSection {
  position: relative;
  min-width: 250px;
  background: #cfcece65;
  margin: auto;
  max-height: 550px;
  z-index: 1;
  border: 2px solid #2c5c69;
  /* box-shadow: 0 0 2px 1px #00e29f; */
  border-radius: 4px;
}


.mcParamRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-left: 4px;
  margin-right: 4px;
  justify-content: space-between;
}

.mcParamMetricLabel {
  font-family: inherit;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  /* margin-bottom: 0.15rem; */
  text-align: center !important;
  color: black
}

.mcParamMetricValue {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 20px;
  margin-left: 6px;
  margin-right: 6px;
  /* margin-bottom: 0.15rem; */
  text-align: center !important;
  color: #2c5c69
}

.printDayDepthContainer {
  /* justify-content:center; */
  position: relative;
  margin-top: 2px;
  min-height: 70px;
  width: calc(100% - 305px);
  color: black !important;
  overflow-wrap: wrap;

}

.McDayDepthContainer {
  position: relative;
  max-height: 530px;
  margin-top: 5px;
  margin-left: 3px;
  min-height: 70px;
  width: 64%;
  color: black;
  overflow-wrap: wrap;
}

.mcActivityInfo {
  position: absolute;
  margin-top: 10px;
  padding-right: 10px;
  margin-left: 8px;
  min-height: 200px;
  max-height: 475px;
  z-index: 2;
  top: 22px;
  right: -18px;
  width: 28%;
  color: black;
  overflow-y: auto;
}

.mcActivityInfo::-webkit-scrollbar {
  width: 5px;
  display: inline;
}

.mcActivityInfo::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.mcActivityInfo:hover::-webkit-scrollbar {
  width: 5px;
  display: inline
}

.mcActivityInfo:hover::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.mcActivityInfo::-webkit-scrollbar-track-piece,
.mcActivityInfo::-webkit-scrollbar-track {
  background-color: transparent;
  width: 8px;
}

.printGoalChunkContainer {
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
  max-height: 550px;
  width: 100%;
  overflow-wrap: normal;
  flex-wrap: wrap;
}

.goalEditRow {
  margin: auto;
  position: relative;

}

.goalEditButton {
  align-self: center;
  position: absolute;
  left: 2px;
  top: 0px;
  width: 35px;
  height: 100%;
  justify-content: center;
  border-radius: 4px;
  padding: 3px;
  color: transparent;
}

.goalEditRow:hover>.goalEditButton {
  border-radius: 4px;
  color: black;

}

.goalEditButton:hover {
  color: #2c5c69;
  cursor: pointer;
  align-self: center;
}

.goalEditIcon {
  text-align: center;
  align-self: center;
  margin: auto;
  position: relative;
}

.goalStrategicIcon {
  text-align: center;
  align-self: center;
  margin: auto;
  position: absolute;
  right: 34px;
  top: 6px;
  cursor: grab;
  display: flex;
}

.goalStreakIcon {
  text-align: center;
  align-self: center;
  margin: auto;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: grab;
  display: flex;
}


.winStreak {
  color: #0a2d47;
  font-weight: 600;
  border-radius: 50%;
  width: fit-content;
  min-width: 26px;
  height: 26px;
  background: #fff;
  border: .12rem solid #0a2d47;
}

.streakText {
  display: flex;
  margin: auto;
  font-size: .7rem;
  text-align: center;
  align-self: center;
}

.streakGrid {
  display: grid;
  column-gap: 1rem;
  position: relative;
  counter-reset: grid-items;
  justify-items: center;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  overflow-y: display;
  max-height: calc(150px);
  margin: 10px;
  z-index: 4;
}

.streakCircle {
  border-radius: 50%;
  width: fit-content;
  min-width: 15px;
  height: 15px;
  padding: 6px;
  background: transparent;
  border: 1.8px solid #0a2d47;
  text-align: center;
  align-self: center;
  position: relative;
}

.streakCircleActive {
  border: 1.8px solid #00e29f;
  background: #00e29f;
  fill-opacity: 1;
}

.streakCircle span {
  display: none;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  /* width: fit-content; */
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 2px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 10;
}

.streakCircle:hover span {
  display: block;
  color: #fff;
}

.goalStreakIcon:hover>.supportDetails {
  display: block !important
}

.loseStreak {
  color: #ff5600;
  font-weight: 600;
  border-radius: 50%;
  width: fit-content;
  min-width: 26px;
  height: 26px;
  background: #fff;
  border: .12rem solid #0a2d47;
}

.goalWinPctIcon {
  text-align: center;
  align-self: center;
  margin: auto;
  position: relative;
}


.printGoalColumn {
  margin: auto;
  position: relative;
  width: 25%;

}

.printableGoalChunkContainer {
  justify-content: center;
  margin: auto;
  display: flex;
  position: relative;
  min-height: 250px;
  max-height: 550px;
  width: 1500px;
  overflow-wrap: normal;
  flex-wrap: wrap;
}

.printableGoalChunkContainer:hover>.PhaseCloseButton {
  display: block;
  cursor: pointer;
}

.mcGoalChunkContainer {
  margin: auto;
  position: relative;
  min-height: 300px;
  max-width: 540px;
  width: 32%;
  max-height: 500px;
  overflow: auto
}

::-webkit-scrollbar {
  width: 10px;
  display: inline;
}

.mcGoalChunkContainer::-webkit-scrollbar-thumb {
  background: #6666669b;
  border-radius: 10px;
}

.mcGoalChunkContainer:hover::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.mcGoalChunkContainer::-webkit-scrollbar-track-piece,
.mcGoalChunkContainer::-webkit-scrollbar-track {
  background-color: transparent;
  width: 12px;
}


.goalbulletchart-hc-cat-title {
  font-size: 12px;
  font-weight: bold;
  color:black
}

.goalbulletchart-hc-cat-title-danger {
  font-size: 12px;
  font-weight: bold;
  color:red
}

.goalbulletchart-hc-cat-unit {
  font-size: 11px;
  font-weight: 400;
  color:black;
  float: right;
  color: 'black' !important
}

/* .printSectionHeader{
  justify-content:center;
} */

.goalEditControls {
  position: absolute;
  height: 0px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.26s ease;
  border-radius: 5px;
  padding: 5px;
  bottom: 0px;
}

.goalEditControls.openControls {
  position: absolute;
  display: block;
  color: black !important;
  top: 40px;
  left: 35px;
  background: #ffffffeb !important;
  overflow: visible;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.26s ease;
  height: fit-content;
  width: calc(100% - 40px);
  border: 2px solid #00e29fD6;
  border-bottom: 4px solid #00e29fD6;
  z-index: 40;
}

.goalEditControls:hover>.CloseBtn {
  display: block;
  cursor: pointer
}