/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
  font-family: 'Animo';
  src: url('../fonts/Animo.eot');
  src: url('../fonts/Animo.eot') format('embedded-opentype'), url('../fonts/Animo.woff2') format('woff2'), url('../fonts/Animo.woff') format('woff'), url('../fonts/Animo.ttf') format('truetype'), url('../fonts/Animo.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.animo-icons {
  display: inline-block;
  font: normal normal normal 1.4em 'Animo';
  vertical-align: middle;
  /* speak: none; */
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
}


.font-icon-detail {
  text-align: center;
  padding: 45px 0 30px;
  border: 1px solid #e44cc4;
  border-radius: .1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.font-icon-detail i {
  color: #FFFFFF;
  font-size: 4.5em;
}

.font-icon-detail p {
  color: #e44cc4 !important;
  margin-top: 30px;
  padding: 0 10px;
  font-size: .7142em;
}

.animo-icon-legend-item {
  font-size: 1.2em;
  color: #323232;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: auto;
  margin-bottom: auto;
  align-self: center
}

.animo-icon-hover {
  color: #323232
}

.animo-icon-hover:hover {
  color: #00e29f !important
}

.animo-icon-table {
  color: #323232;
  padding: 3px 3px 3px 3px;
  border: 1px solid #00e29f00;
  background-color: transparent;
}


.animo-icon-table:hover {
  color: #2c5c69 !important;
  transform: scale(1.2);
  background-color: #eeeeee !important;
  border-color: #00e29f !important;
  border: 1px solid #00e29f !important;
  border-radius: 5px;
  padding: 3px 3px 3px 3px;
}

.animo-icon-danger-hover {
  color: #FFFFFF
}

.animo-icon-danger-hover:hover {
  color: #ec250d !important
}

.animo-icon-white-hover {
  color: #FFFFFF
}

.animo-icon-white-hover:hover {
  color: #00e29f !important
}

.animo-icon-primary-hover {
  color: #FFFFFF
}

.animo-icon-primary-hover:hover {
  color: #2c5c69 !important
}

.animo-icon-primary-success-hover {
  color: #2c5c69
}

.animo-icon-primary-success-hover:hover {
  color: #00e29f !important
}

.animo-icon-success {
  color: #00e29f
}

.animo-icon-alert {
  color: #E1B95B
}

.animo-icon-warning {
  color: #C68135
}

.animo-icon-danger {
  color: #ec250d
}

.animo-icon-primary {
  color: #2c5c69
}


.success-hover:hover {
  color: #00e29f !important
}

.danger-hover:hover {
  color: #ec250d !important
}

.white-hover:hover {
  color: #ffffff !important
}

.animo-icon-white {
  color: #e3e3e3
}

.animo-icon-black {
  color: black
}


.animo-icons-sm {
  font-size: 0.8em !important;
  ;
}

.animo-icons-lg {
  font-size: 2.4em;
}

.animo-icons-lg-nav {
  font-size: 3.4em;
}

.animo-icons-nav {
  font-size: 3.4em;
}

/* absolute units */
.animo-icons-16 {
  font-size: 16px !important;
}

.animo-icons-18 {
  font-size: 18px !important;
}

/* .animo-icons-22 {
  font-size: 22px !important;
} */

.animo-icons-26 {
  font-size: 26px !important;
}

.animo-icons-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.animo-icons-bg-square,
.animo-icons-bg-circle {
  padding: 0.35em;
}

.animo-icons-bg-square {
  border-radius: 30%;
  background-color: #FFFFFF;
  border: 1.5px solid #2c5c69
}

/*------------------------
  list icons
-------------------------*/

/*------------------------
  spinning icons
-------------------------*/

.animo-icons-is-spinning {
  -webkit-animation: animo-icons-spin 2s infinite linear;
  -moz-animation: animo-icons-spin 2s infinite linear;
  animation: animo-icons-spin 2s infinite linear;
}

@-webkit-keyframes animo-icons-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes animo-icons-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes animo-icons-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/
.animo-icons-flip-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.animo-icons-flip-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.animo-icons-flip-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

/*------------------------
	icons
-------------------------*/

.icon-ic_speaker_notes_48px::before {
  content: "\ea02";
}

.icon-comment-add::before {
  content: "\ea03";
}

.icon-feedback::before {
  content: "\ea04";
}

.icon-feedback-survey::before {
  content: "\ea05";
}

.icon-edit-curves::before {
  content: "\ea06";
}

.icon-trip::before {
  content: "\ea07";
}

.icon-collab-settings::before {
  content: "\ea08";
}

.icon-ic_do_not_disturb::before {
  content: "\ea09";
}

.icon-hydro-settings::before {
  content: "\ea0a";
}

.icon-oil::before {
  content: "\ea0b";
}

.icon-magnifier-help::before {
  content: "\ea0c";
}

.icon-full-analytics::before {
  content: "\ea0d";
}

.icon-mobile-analytics::before {
  content: "\ea0e";
}

.icon-tabulate-data-chunk::before {
  content: "\ea0f";
}

.icon-report-settings::before {
  content: "\ea10";
}

.icon-analytics-svgrepo-com::before {
  content: "\ea11";
}

.icon-edr-trace::before {
  content: "\ea12";
}

.icon-file-article::before {
  content: "\ea13";
}

.icon-torque-settings::before {
  content: "\ea14";
}

.icon-save-old::before {
  content: "\ea15";
}

.icon-collab-item::before {
  content: "\ea16";
}

.icon-save-outline::before {
  content: "\ea17";
}

.icon-collab-bundle::before {
  content: "\ea1a";
}

.icon-precision-edr::before {
  content: "\ea1b";
}

.icon-low-freq::before {
  content: "\ea1c";
}

.icon-high-freq::before {
  content: "\ea1d";
}

.icon-printer::before {
  content: "\ea1e";
}

.icon-print-preview::before {
  content: "\ea1f";
}

.icon-hide::before {
  content: "\ea20";
}

.icon-add-time-summary::before {
  content: "\ea21";
}

.icon-rotate-orientation::before {
  content: "\ea22";
}

.icon-scatter-plot::before {
  content: "\ea23";
}

.icon-line-plot::before {
  content: "\ea24";
}

.icon-add-collab-group::before {
  content: "\ea25";
}

.icon-calculator::before {
  content: "\ea26";
}

.icon-WalkThrough::before {
  content: "\ea27";
}

.icon-video-tutorial::before {
  content: "\ea28";
}

.icon-video-player::before {
  content: "\ea29";
}

.icon-new-feature::before {
  content: "\ea2a";
}

.icon-new-feature-2::before {
  content: "\ea2b";
}

.icon-swap-vertical::before {
  content: "\ea2c";
}

.icon-highlight-range::before {
  content: "\ea2d";
}

.icon-slideshow::before {
  content: "\ea2e";
}

.icon-collab-view-busy::before {
  content: "\ea2f";
}

.icon-collab-view::before {
  content: "\ea30";
}

.icon-view-group::before {
  content: "\ea31";
}

.icon-study-topic::before {
  content: "\ea32";
}

.icon-duplicate::before {
  content: "\ea33";
}

.icon-share::before {
  content: "\ea34";
}

.icon-computer-upload::before {
  content: "\ea35";
}

.icon-slide-left::before {
  content: "\ea36";
}

.icon-slide-right::before {
  content: "\ea37";
}

.icon-drilling-time::before {
  content: "\ea39";
}

.icon-total-footage::before {
  content: "\ea3a";
}

.icon-hole-sizes::before {
  content: "\ea3b";
}

.icon-drilling-rig::before {
  content: "\ea3c";
}

.icon-barrel-per-day::before {
  content: "\ea3d";
}

.icon-one-run::before {
  content: "\ea3e";
}

.icon-total-time::before {
  content: "\ea3f";
}

.icon-days-elapsed::before {
  content: "\ea40";
}

.icon-well-design::before {
  content: "\ea41";
}

.icon-formation::before {
  content: "\ea42";
}

.icon-unique-fingerprint::before {
  content: "\ea43";
}

.icon-incident-report::before {
  content: "\ea44";
}

.icon-well-design-2::before {
  content: "\ea45";
}

.icon-idea-decision::before {
  content: "\ea46";
}

.icon-collab-branch::before {
  content: "\ea47";
}

.icon-decision-3::before {
  content: "\ea48";
}

.icon-value-decision::before {
  content: "\ea49";
}

.icon-intervals::before {
  content: "\ea4a";
}

.icon-npt-time::before {
  content: "\ea4b";
}

.icon-npt-event::before {
  content: "\ea4c";
}

.icon-well-details::before {
  content: "\ea4d";
}

.icon-well-partners::before {
  content: "\ea4e";
}

.icon-is-default::before {
  content: "\ea4f";
}

.icon-edit-range::before {
  content: "\ea50";
}

.icon-add-labels::before {
  content: "\ea51";
}

.icon-remove-labels::before {
  content: "\ea52";
}

.icon-fill::before {
  content: "\ea53";
}

.icon-comment-add-2::before {
  content: "\ea56";
}

.icon-search::before {
  content: "\ea5b";
}

.icon-add-intervals::before {
  content: "\ea5f";
}

.icon-add-formations::before {
  content: "\ea60";
}

.icon-view-intervals::before {
  content: "\ea61";
}

.icon-view-formations::before {
  content: "\ea62";
}

.icon-add-trips::before {
  content: "\ea63";
}

.icon-view-trips::before {
  content: "\ea64";
}

.icon-drill-bit::before {
  content: "\ea66";
}

.icon-download-csv::before {
  content: "\ea67";
}

.icon-clear-filter::before {
  content: "\ea68";
}

.icon-filter::before {
  content: "\ea69";
}

.icon-day-depth::before {
  content: "\ea6a";
}

.icon-drill-only::before {
  content: "\ea6b";
}

.icon-moving-truck::before {
  content: "\ea6c";
}

.icon-comments::before {
  content: "\ea6d";
}

.icon-bdp-dvd::before {
  content: "\ea6e";
}

.icon-afe-dvd::before {
  content: "\ea6f";
}

.icon-select-all-kpi::before {
  content: "\ea70";
}

.icon-clear-all-kpi::before {
  content: "\ea71";
}

.icon-normalize::before {
  content: "\ea72";
}

.icon-future-schedule::before {
  content: "\ea73";
}

.icon-all-schedule::before {
  content: "\ea74";
}

.icon-tda::before {
  content: "\ea75";
}

.icon-tda-view::before {
  content: "\ea77";
}

.icon-hydro-view::before {
  content: "\ea78";
}

.icon-tda-settings::before {
  content: "\ea79";
}

.icon-mud-inventory::before {
  content: "\ea7a";
}

.icon-bha-from-template::before {
  content: "\ea7b";
}

.icon-transfer::before {
  content: "\ea7c";
}

.icon-tank-volumes::before {
  content: "\ea7d";
}

.icon-wits-controller::before {
  content: "\ea7e";
}

.icon-global-navigation::before {
  content: "\ea7f";
}

.icon-wits-start::before {
  content: "\ea80";
}

.icon-statistics::before {
  content: "\ea81";
}

.icon-historic::before {
  content: "\ea82";
}

.icon-speedometer::before {
  content: "\ea83";
}

.icon-expand::before {
  content: "\ea84";
}

.icon-minimize::before {
  content: "\ea85";
}

.icon-offset-radius::before {
  content: "\ea86";
}

.icon-show-eye::before {
  content: "\ea88";
}

.icon-assigned::before {
  content: "\ea89";
}

.icon-not-assigned::before {
  content: "\ea8a";
}

.icon-segment-settings::before {
  content: "\ea8b";
}

.icon-share-2::before {
  content: "\ea8c";
}

.icon-stop-button::before {
  content: "\ea8d";
}

.icon-link::before {
  content: "\ea8e";
}

.icon-connected-source::before {
  content: "\ea8f";
}

.icon-info-structured::before {
  content: "\ea90";
}

.icon-info-stream::before {
  content: "\ea91";
}

.icon-pulse::before {
  content: "\ea92";
}

.icon-mwd-data::before {
  content: "\ea93";
}

.icon-segment-results::before {
  content: "\ea94";
}

.icon-refresh-analytics::before {
  content: "\ea95";
}

.icon-refresh-active::before {
  content: "\ea96";
}

.icon-refresh-rig-states::before {
  content: "\ea97";
}

.icon-refresh-all-segments::before {
  content: "\ea98";
}

.icon-refresh-dvd::before {
  content: "\ea99";
}

.icon-night-day::before {
  content: "\ea9a";
}

.icon-day-night::before {
  content: "\ea9b";
}

.icon-day-night-2::before {
  content: "\ea9c";
}

.icon-ops-dash-controls::before {
  content: "\ea9d";
}

.icon-hide-string-surface::before {
  content: "\ea9e";
}

.icon-view-string-surface::before {
  content: "\ea9f";
}

.icon-new-pipe-shuffle::before {
  content: "\eaa0";
}

.icon-text-color::before {
  content: "\eaa1";
}

.icon-text-box::before {
  content: "\eaa2";
}

.icon-callout::before {
  content: "\eaa3";
}

.icon-trip-out::before {
  content: "\eaa4";
}

.icon-casing-run::before {
  content: "\eaa5";
}

.icon-trip-in::before {
  content: "\eaa6";
}

.icon-drilling::before {
  content: "\eaa7";
}

.icon-critical-error::before {
  content: "\eaa8";
}

.icon-warning-error::before {
  content: "\eaa9";
}

.icon-low-alert::before {
  content: "\eaaa";
}

.icon-select-all::before {
  content: "\eaab";
}

.icon-add-phase::before {
  content: "\eaac";
}

.icon-view-phase::before {
  content: "\eaad";
}

.icon-verified::before {
  content: "\eaaf";
}

.icon-circular-chart::before {
  content: "\eab0";
}

.icon-audit::before {
  content: "\eab1";
}

.icon-slide::before {
  content: "\eab2";
}

.icon-stand::before {
  content: "\eab3";
}

.icon-idea::before {
  content: "\eab4";
}

.icon-single-day::before {
  content: "\eab5";
}

.icon-success-goal::before {
  content: "\eab6";
}

.icon-dog-leg::before {
  content: "\eab8";
}

.icon-build-rate::before {
  content: "\eab9";
}

.icon-turn-rate::before {
  content: "\eaba";
}

.icon-effective-toolface::before {
  content: "\eabb";
}

.icon-percentage::before {
  content: "\eabc";
}

.icon-motor::before {
  content: "\eabd";
}

.icon-add-phase::before {
  content: "\eaac";
}

.icon-view-phase::before {
  content: "\eaad";
}

.icon-verified::before {
  content: "\eaaf";
}

.icon-rotating::before {
  content: "\eabe";
}

.icon-slide-vs-rotate::before {
  content: "\eabf";
}

.icon-circular-chart-2::before {
  content: "\eac1";
}

.icon-slide-line::before {
  content: "\eac0";
}

.icon-view-table::before {
  content: "\eacb";
}

.icon-hide-table::before {
  content: "\eacc";
}

.icon-table::before {
  content: "\eacd";
}

.icon-uea2b-::before {
  content: "\ea01";
}

.icon-inspection::before {
  content: "\eace";
}

.icon-power-on::before {
  content: "\eacf";
}

.icon-3d-cube::before {
  content: "\ead0";
}

.icon-2d::before {
  content: "\ead1";
}

.icon-3d-model::before {
  content: "\ead2";
}

.icon-uea2b-::before {
  content: "\ea01";
}

.icon-gantt::before {
  content: "\ead3";
}

.icon-slide-line-2::before {
  content: "\ead4";
}

.icon-vertical-drilling::before {
  content: "\ead5";
}

.icon-curve-drilling::before {
  content: "\ead6";
}

.icon-fluid-drop::before {
  content: "\ead7";
}

.icon-road-map::before {
  content: "\ead8";
}

.icon-hydraulics-overview::before {
  content: "\ead9";
}

.icon-surveys::before {
  content: "\eada";
}

.icon-point-map::before {
  content: "\eadc";
}

.icon-failed-casing-run::before {
  content: "\eadd";
}

.icon-liner-dp-trip-out::before {
  content: "\eade";
}

.icon-failed-casing-trip-out::before {
  content: "\eadf";
}

.icon-learning::before {
  content: "\eae0";
}

.icon-link-2::before {
  content: "\eae1";
}

.icon-strategy::before {
  content: "\eae2";
}

.icon-toolface::before {
  content: "\eae3";
}

.icon-offset-select::before {
  content: "\eae4";
}

.icon-offset-deselect::before {
  content: "\eae5";
}

.icon-offset-compare::before {
  content: "\eae6";
}

.icon-toolface-target::before {
  content: "\eae7";
}

.icon-broomstick::before {
  content: "\eaed";
}

.icon-torque::before {
  content: "\eaec";
}

.icon-bit-depth::before {
  content: "\eaee";
}

.icon-tap::before {
  content: "\eaef";
}

.icon-comment-table::before {
  content: "\eaf0";
}

.icon-npt-table::before {
  content: "\eaf1";
}

.icon-patch::before {
  content: "\eaf2";
}

.icon-bulb::before {
  content: "\eaf3";
}

.icon-lithology-map::before {
  content: "\eaf4";
}

.icon-formation-change::before {
  content: "\eaf5";
}

.icon-lithology-map-2::before {
  content: "\eaf6";
}

.icon-fault::before {
  content: "\eaf7";
}

.icon-fault-2::before {
  content: "\eaf8";
}

.icon-geology::before {
  content: "\eaf9";
}

.icon-bug-warning::before {
  content: "\eafa";
}

.icon-formation-change::before {
  content: "\eafb";
}

.icon-oil-pumpjack::before {
  content: "\eafc";
}

.icon-stacked-pay::before {
  content: "\eafd";
}

.icon-display-layers::before {
  content: "\eafe";
}

.icon-select-formation-tops::before {
  content: "\eaff";
}

.icon-fault-3::before {
  content: "\eb00";
}

.icon-view-formations-2::before {
  content: "\eb01";
}

.icon-cuttings-sample::before {
  content: "\eb02";
}

.icon-lwd-graphs::before {
  content: "\eb03";
}

.icon-tabulate::before {
  content: "\eb04";
}

.icon-machine-learning::before {
  content: "\eb05";
}

.icon-developing::before {
  content: "\eb06";
}

.icon-analysis::before {
  content: "\eb07";
}

.icon-view-parameters::before {
  content: "\eb08";
}

.icon-user-report::before {
  content: "\eb09";
}

.icon-time-cost-analysis::before {
  content: "\eb0a";
}

.icon-predictive-models::before {
  content: "\eb0b";
}

.icon-view-kpi::before {
  content: "\eb0c";
}

.icon-drilling-bit::before {
  content: "\eb0d";
}

.icon-transparency::before {
  content: "\eb0e";
}

.icon-transparent-image::before {
  content: "\eb0f";
}

.icon-physical::before {
  content: "\eb10";
}

.icon-pulley::before {
  content: "\eb11";
}

.icon-pip-overlay::before {
  content: "\eb12";
}

.icon-trace-layout::before {
  content: "\eb13";
}

.icon-storage::before {
  content: "\eb14";
}

.icon-search-bar::before {
  content: "\eb15";
}

.icon-report-details::before {
  content: "\eb16";
}

.icon-checkbox::before {
  content: "\eb17";
}

.icon-rig-ranking::before {
  content: "\eb18";
}

.icon-show-overlay::before {
  content: "\eb19";
}

.icon-swap-layers::before {
  content: "\eb1a";
}

.icon-edit_layer::before {
  content: "\eb1b";
}

.icon-tda-models::before {
  content: "\eb1c";
}

.icon-line-width::before {
  content: "\eb1d";
}

.icon-tda-model-2::before {
  content: "\eb1e";
}

.icon-square::before {
  content: "\eb1f";
}

.icon-list::before {
  content: "\eb20";
}

.icon-scatter-graph::before {
  content: "\eb21";
}

.icon-scatter-dots::before {
  content: "\eb22";
}

.icon-diamond::before {
  content: "\eb23";
}

.icon-circle::before {
  content: "\eb24";
}

.icon-star::before {
  content: "\eb25";
}

.icon-triangle::before {
  content: "\eb26";
}

.icon-line::before {
  content: "\eb27";
}


.icon-split::before {
  content: "\eb28";
}

.icon-vibrate::before {
  content: "\eb29";
}

.icon-Side-track::before {
  content: "\eb2a";
}

.icon-merge::before {
  content: "\eb2b";
}

.icon-plan-detail::before {
  content: "\eb2c";
}

.icon-office-push-pin::before {
  content: "\eb2d";
}

.icon-friction::before {
  content: "\eb2e";
}

.icon-cost-reduction::before {
  content: "\eb2f";
}

.icon-statistic-view::before {
  content: "\eb30";
}

.icon-delete-node::before {
  content: "\eb31";
}

.icon-add-level::before {
  content: "\eb32";
}

.icon-adjust-point::before {
  content: "\eb33";
}

.icon-decision-point::before {
  content: "\eb34";
}

.icon-add-node::before {
  content: "\eb35";
}


.icon-data-settings::before {
  content: "\eb36";
}

.icon-X-ray-point::before {
  content: "\eb37";
}

.icon-x-ray-point-clear::before {
  content: "\eb38";
}

.icon-cloud-storage::before {
  content: "\eb39";
}

.icon-AI-Computing::before {
  content: "\eb3a";
}

.icon-seamless-mind::before {
  content: "\eb3b";
}

.icon-AI-brain::before {
  content: "\eb3c";
}

.icon-AI-Cloud-Computing::before {
  content: "\eb3d";
}

.icon-AI-Cloud-Computing-light::before {
  content: "\eb3e";
}

.icon-AI-Idea-Generation::before {
  content: "\eb3f";
}

.icon-AI-cloud-connected::before {
  content: "\eb40";
}

.icon-cloud-intelligence::before {
  content: "\eb41";
}

.icon-connected-team::before {
  content: "\eb42";
}

.icon-ai-analytics::before {
  content: "\eb43";
}

.icon-AI-support::before {
  content: "\eb44";
}

.icon-knowledge::before {
  content: "\eb45";
}

.icon-AI-Synergy::before {
  content: "\eb46";
}

.icon-AI-mobile::before {
  content: "\eb47";
}

.icon-AI-view::before {
  content: "\eb48";
}

.icon-futuristic::before {
  content: "\eb49";
}

.icon-ai-reports::before {
  content: "\eb4a";
}

.icon-adjust-dvd-curve::before {
  content: "\eb4b";
}

.icon-afe-distance::before {
  content: "\eb4c";
}

.icon-drilling-rig-1::before {
  content: "\eb4d";
}

.icon-alphabetically-z-to-a::before {
  content: "\eb4e";
}

.icon-numerical-1-to-9::before {
  content: "\eb4f";
}

.icon-decreasing-list::before {
  content: "\eb51";
}

.icon-increasing-list::before {
  content: "\eb52";
}

.icon-categorize::before {
  content: "\eb54";
}

.icon-hierarchy::before {
  content: "\eb55";
}

.icon-rake-2::before {
  content: "\eb56";
}

.icon-rake::before {
  content: "\eb57";
}

.icon-numerical-9-to-1::before {
  content: "\eb58";
}

.icon-alphabetically-a-to-z::before {
  content: "\eb59";
}

.icon-select-point::before {
  content: "\eb5a";
}

.icon-hover-banner::before {
  content: "\eb5b";
}

.icon-data-search::before {
  content: "\eb5c";
}

.icon-cost-breakdown::before {
  content: "\eb5d";
}

.icon-line-chart::before {
  content: "\eb5e";
}

.icon-percent-breakdown::before {
  content: "\eb5f";
}

.icon-circular-bar-chart::before {
  content: "\eb60";
}

.icon-spider-chart::before {
  content: "\eb61";
}

.icon-pyramid-rank::before {
  content: "\eb62";
}

.icon-box-whiskers::before {
  content: "\eb63";
}

.icon-star-award::before {
  content: "\eb66";
}

.icon-crown-ranking::before {
  content: "\eb67";
}

.icon-shooting-star::before {
  content: "\eb68";
}

.icon-star-leader::before {
  content: "\eb69";
}

.icon-star-ranking::before {
  content: "\eb6b";
}

.icon-hierarchy-order::before {
  content: "\eb6c";
}

.icon-connect-workflow::before {
  content: "\eb6d";
}

.icon-compare-points::before {
  content: "\eb6e";
}

.icon-compare-scale::before {
  content: "\eb6f";
}

.icon-layout::before {
  content: "\eb83";
}

.icon-download-setting::before {
  content: "\eb84";
}

.icon-add-page::before {
  content: "\eb85";
}

.icon-new-window::before {
  content: "\eb86";
}

.icon-add-table-item::before {
  content: "\eb87";
}

.icon-pdf-file::before {
  content: "\eb88";
}

.icon-svg-file::before {
  content: "\eb89";
}

.icon-csv-file::before {
  content: "\eb8a";
}

.icon-xlsx-file::before {
  content: "\eb8b";
}

.icon-ppt-file::before {
  content: "\eb8c";
}

.icon-png-file::before {
  content: "\eb8d";
}

.icon-create-custom-channel::before {
  content: "\eb8e";
}

.icon-drag-chart::before {
  content: "\eb8f";
}

.icon-sound-bars::before {
  content: "\eb90";
}

.icon-sound-waves::before {
  content: "\eb91";
}

.icon-density-bubbles::before {
  content: "\eb92";
}

.icon-scale-bars::before {
  content: "\eb93";
}

.icon-slider::before {
  content: "\eb94";
}


.icon-dependent-flow::before {
  content: "\eb95";
}

.icon-re-size::before {
  content: "\eb96";
}

.icon-split-segment::before {
  content: "\eb97";
}

.icon-apps::before {
  content: "\eb98";
}

.icon-perspective::before {
  content: "\eb99";
}

.icon-ruler::before {
  content: "\eb9b";
}

.icon-drop-down::before {
  content: "\eb9c";
}

.icon-flatten::before {
  content: "\eb9d";
}

.icon-merge-segment::before {
  content: "\eb9e";
}

.icon-prototype::before {
  content: "\eb9f";
}

.icon-design-tools::before {
  content: "\eba0";
}

.icon-blue-print-pencil::before {
  content: "\eba1";
}

.icon-cubes::before {
  content: "\eba2";
}

.icon-hydraulic-details::before {
  content: "\eba4";
}

.icon-algorithm::before {
  content: "\eba5";
}

.icon-infographic::before {
  content: "\eba6";
}

.icon-sunburst::before {
  content: "\eba7";
}

.icon-analyze-breakdown::before {
  content: "\eba8";
}

.icon-clustor-analysis::before {
  content: "\eba9";
}

.icon-low-tide::before {
  content: "\ebaa";
}

.icon-hydraulic-energy::before {
  content: "\ebab";
}

.icon-mud-refresh::before {
  content: "\ebad";
}

.icon-hydraulic-check::before {
  content: "\ebae";
}

.icon-mud-plan::before {
  content: "\ebaf";
}

.icon-tide::before {
  content: "\ebb0";
}

.icon-mud-report::before {
  content: "\ebb1";
}

.icon-compare::before {
  content: "\ebb2";
}

.icon-histogram::before {
  content: "\ebb3";
}

.icon-water::before {
  content: "\ebb4";
}

.icon-trend-ranges::before {
  content: "\ebb5";
}

.icon-sensitivities::before {
  content: "\ebb6";
}

.icon-growth::before {
  content: "\ebb7";
}

.icon-trend-chart::before {
  content: "\ebb8";
}

.icon-fluid-analysis::before {
  content: "\ebb9";
}

.icon-advance::before {
  content: "\ebba";
}

.icon-refresh-date::before {
  content: "\ebbb";
}

.icon-fluid-droplet::before {
  content: "\ebbd";
}

.icon-nearby-large::before {
  content: "\ebbe";
}

.icon-setting::before {
  content: "\ebbf";
}

.icon-setting-toggle::before {
  content: "\ebc0";
}

.icon-nearby::before {
  content: "\ebc1";
}

.icon-auto-offset::before {
  content: "\ebc2";
}

.icon-nearby-small::before {
  content: "\ebc3";
}

.icon-PersonRank::before {
  content: "\ebc4";
}

.icon-BubbleChart::before {
  content: "\ebc5";
}

.icon-DonutChart::before {
  content: "\ebc6";
}

.icon-data-link::before {
  content: "\ebc7";
}

.icon-integration-links::before {
  content: "\ebc8";
}

.icon-drop-cap::before {
  content: "\ebc9";
}

.icon-toggle::before {
  content: "\ebca";
}

.icon-tag::before {
  content: "\ebcb";
}

.icon-add-tag::before {
  content: "\ebce";
}

.icon-star-tag::before {
  content: "\ebcf";
}

.icon-star-tag-2::before {
  content: "\ebd0";
}

.icon-lesson-learned::before {
  content: "\ebd1";
}

.icon-knowledge-cap::before {
  content: "\ebd2";
}

.icon-deep-learning-profit::before {
  content: "\ebd3";
}

.icon-linked-knowledge::before {
  content: "\ebd4";
}

.icon-interactive::before {
  content: "\ebd5";
}

.icon-roadmap-design::before {
  content: "\ebd6";
}

.icon-vibration-mitigation::before {
  content: "\ebd7";
}

.icon-animo-road-map::before {
  content: "\ebd8";
}

.icon-well-program::before {
  content: "\ebd9";
}

.icon-execution-timeline::before {
  content: "\ebda";
}

.icon-integration::before {
  content: "\ebdb";
}

.icon-infinitas::before {
  content: "\ebdc";
}

.icon-digital-twin::before {
  content: "\ebdd";
}

.icon-interactivity::before {
  content: "\ebde";
}

.icon-blockchain::before {
  content: "\ebdf";
}

.icon-disruption::before {
  content: "\ebe0";
}

.icon-simplicity::before {
  content: "\ebe1";
}

.icon-trend-bar-graph::before {
  content: "\ebe2";
}

.icon-focus::before {
  content: "\ebe3";
}

.icon-focus-rank::before {
  content: "\ebe4";
}

.icon-multi-well::before {
  content: "\ebe5";
}

.icon-ranked-segment::before {
  content: "\ebe6";
}


.icon-save-success::before {
  content: "\ebe7";
}

.icon-save-edit::before {
  content: "\ebe8";
}

.icon-shrink::before {
  content: "\ebe9";
}

.icon-database-blocked::before {
  content: "\ebea";
}

.icon-data-validated::before {
  content: "\ebeb";
}

.icon-settings-gear::before {
  content: "\ebec";
}

.icon-save-error::before {
  content: "\ebed";
}

.icon-expand-1::before {
  content: "\ebee";
}

.icon-collapse::before {
  content: "\ebef";
}

.icon-data-settings-1::before {
  content: "\ebf0";
}

.icon-data-error::before {
  content: "\ebf1";
}

.icon-data-connected::before {
  content: "\ebf2";
}

.icon-edit-success::before {
  content: "\ebf3";
}

.icon-database-success::before {
  content: "\ebf4";
}

.icon-save-delete::before {
  content: "\ebf5";
}

.icon-param-range-warning::before {
  content: "\ebf6";
}

.icon-stuck-pipe-warning::before {
  content: "\ebf7";
}

.icon-blowout::before {
  content: "\ebf8";
}

.icon-tool-health::before {
  content: "\ebf9";
}

.icon-directional-warning::before {
  content: "\ebfa";
}

.icon-step-test::before {
  content: "\ebfb";
}

.icon-anti-collision-risk::before {
  content: "\ebfc";
}

.icon-anti-collision::before {
  content: "\ebfd";
}

.icon-anti-collision-avoidance::before {
  content: "\ebfe";
}

.icon-single-well::before {
  content: "\ebff";
}

.icon-dashed-line::before {
  content: "\ec00";
}

.icon-horizontal-dashed-line::before {
  content: "\ec01";
}

.icon-dotted-line::before {
  content: "\ec02";
}

.icon-dashboard::before {
  content: "\ec03";
}

.icon-data-classification::before {
  content: "\ec04";
}

.icon-data-filtering::before {
  content: "\ec05";
}

.icon-data-labeling::before {
  content: "\ec08";
}

.icon-performance-reporting::before {
  content: "\ec09";
}

.icon-drilling-dna::before {
  content: "\ec0a";
}

.icon-complex-calc-builder::before {
  content: "\ec0b";
}

.icon-match-recognition::before {
  content: "\ec0c";
}

.icon-model-testing::before {
  content: "\ec0d";
}

.icon-data-science::before {
  content: "\ec0e";
}

.icon-regression::before {
  content: "\ec0f";
}

.icon-identification::before {
  content: "\ec10";
}

.icon-pattern::before {
  content: "\ec11";
}

.icon-pattern-labeling::before {
  content: "\ec12";
}

.icon-geo-steering::before {
  content: "\ec13";
}

.icon-add-branch::before {
  content: "\ec15";
}

.icon-data-analysis::before {
  content: "\ec16";
}

.icon-range-forecast::before {
  content: "\ec17";
}

.icon-add-geo-steering-command::before {
  content: "\ec18";
}

.icon-forecast::before {
  content: "\ec19";
}

.icon-leak-detector::before {
  content: "\ec1b";
}

.icon-AnimoA::before {
  content: "\ec1d";
}

.icon-routing::before {
  content: "\ec21";
}

.icon-Logo::before {
  content: "\ec22";
}

.icon-insight::before {
  content: "\ec23";
}

.icon-statistical-analysis::before {
  content: "\ec27";
}
.icon-notification::before {
  content: "\ec28";
}
