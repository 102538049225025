.showPageConfigToggle {
  position: absolute;
  top: 5px;
  right: 5px;
}

.pageConfigModal {
  position: fixed;
  display: inline-block;
  overflow: visible;
  z-index: 1000;
  top: 40%;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 850px;
  height: fit-content;
  max-height: 800px;
  padding: 15px;
  border-radius: 5px;
  background: #3e3e3eE6;
  flex-grow: 1
}

.pageConfigModal .quickSelect {
  width: 75%;
  /* height: 70%; */
  min-width: 600px;
}

.CloseBtn {
  position: absolute;
  right: 2px;
  top: 1px;
  font-size: 16px;
  color: #ff5600;
  padding: 5px;
  display: none;
}

.pageConfigModal:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.pageConfigModalBody {
  padding: 10px;
  margin: 2px;
  color: black;
  /* margin: 20px; */
  background: white;
  border-radius: 4px;
}

.pageConfigTitleRow {
  display: flex;
  position: relative;
}

.pageConfigHeader {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 5px;
  justify-content: space-between
}

.pageConfigTitle {
  width: 470px;
  float: left;
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45em;
  margin-Left: 10px;
  align-self: center;
}

.pageConfigControls {
  width: 250px;
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45em;
  align-self: center;
  text-align: right
}

.pageConfigRow {
  position: relative;
  display: flex;
  width: 100%;
  overflow-wrap: wrap
}

.controlsModal {
  position: fixed;
  display: inline-block;
  z-index: 1000;
  top: 120px;
  width: 80%;
  left: calc(50% + 20px);
  transform: translate(-50%);
  min-width: 400px;
  height: fit-content;
  overflow: hidden;
  max-height: 92%;
  padding: 20px;
  border-radius: 5px;
  background: #3e3e3eD6;
  backdrop-filter: blur(2px);
  flex-grow: 1
}

.controlsModalTitleRow {
  display: flex;
  position: relative;
}

.controlsModalTitle {
  width: 470px;
  float: left;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25em;
  margin-Left: 10px;
  margin-top: -10px;
  align-self: center;
}

.controlsModal:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.interalControlsContainer {
  position: relative;
  width: 100%;
  border-radius: 5px;
  color: black;
  background-color: white;
  border: 2px solid #2c5c69 !important;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 10px
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  z-index: 4
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #2c5c69;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #fcfcfc;
  backdrop-filter: blur(2px);
  border: 1px solid #2c5c69 !important;
  border-radius: 4px;
}

.animo-grid-app-title-bar {
  height: 30px;
  background: #fcfcfc;
  padding: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  /* border-bottom: 1px solid #2c5c69 !important; */
  display: flex
}

.animo-grid-app-settings {
  transition: all 200ms ease;
  transition-property: left, top;
}

.animo-app-container {
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
}

.animo-grid-app-footer {
  height: 20px;
  background: transparent;
  opacity: 0;
  /* border-top: 1px solid #2c5c69 !important; */
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  bottom: 0px;
  padding-left: 10;
  padding-right: 10;
  display: flex;
  position: relative
}

.animo-grid-app-footer:hover {
  visibility: visible;
  opacity: 1;

}

.animo-grid-app-footer:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.appControls {
  position: absolute;
  width: 100%;
  min-width: 230px;
  /* height: 10px; */
  /* left: -15px; */
  top: 20px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  z-index: 125;
}

.appControls:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.openAppControls {
  background: rgba(51, 51, 51, 0.45);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: black !important;
  visibility: visible;
  overflow: visible;
  transform-origin: top;
  height: fit-content;
  transform: scaleY(1);
  min-height: 200px;
  /* min-height: 90%; */

}

.darkModeOpenControls {
  background: rgba(51, 51, 51, 0.45);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: white !important;
  visibility: visible;
  overflow: visible;
  transform-origin: bottom;
  height: fit-content;
  transform: scaleY(1);
  /* min-height: 90%; */

}