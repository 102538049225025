.showOffsetToggle {
  position: absolute;
  top: 5px;
  right: 5px;
}

.offsetModal {
  position: fixed;
  display: inline-block;
  overflow: visible;
  z-index: 1000;
  top: 50%;
  min-width: 400px;
  max-width: 85%;
  width: fit-content;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  height: fit-content;
  min-height: 180px;
  max-height: 800px;
  padding: 15px;
  background: #3e3e3eD6;
  flex-grow: 1
}

.offsetModal .quickSelect {
  width: 75%;
  /* height: 70%; */
  min-width: 600px;
}

.offsetModal .CloseBtn {
  position: absolute;
  right: 2px;
  top: -1px;
  font-size: 16px;
  color: #ff5600;
  z-index: 12005;
  padding: 2px;
  display: none;
}

.offsetModal:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.offsetModalBody {
  padding: 6px;
  margin: 2px;
  color: black;
  /* margin: 20px; */
  background: white;
  border-radius: 4px;
  overflow-y: hidden;
}

.offsetReviewTable {
  margin: auto;
  position: relative;
  min-height: 10px;
  height: fit-content;
  width: 850px;
}

.schematicViewer {
  display: flex;
  justify-content: left;
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.775);
  box-shadow: 0 0 3px 1px #2c5c69;
  margin-top: 0px;
  background: white;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 600px
}

.schematicContainer {
  border: #00e29f 2px solid;
  height: 100%;
  position: relative;
  overflow: hidden;
  width: fit-content;
  position: relative;
  display: grid;
  grid-auto-flow: column;
}

.offsetContainer {
  position: relative;
  display: flex;
  /* width: fit-content; */
  width: calc(45% - 42px);
  justify-content: center
}

.wellboreContainer {
  color: black !important;
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 200px;
  flex-grow: 1;
  margin-bottom: 45px;
  margin-right: 10px;
}

.offsetLegend {
  position: absolute;
  bottom: 0;
  display: inline-box;
  overflow: hidden;
  width: 30%;
  left: 8%;
  flex-grow: 1;
  margin-bottom: 5px;
}

.legendName {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: elipsis
}

.offsetNav {
  position: absolute;
  bottom: 0;
  display: inline-block;
  overflow: hidden;
  width: 45%;
  left: 55%;
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.775);
}

.offsetToolbar {
  min-height: 180px;
  max-height: 200px;
  position: fixed;
  /* display: inline-block; */
  justify-content: space-around;
  transition: .25s ease-in-out;
  background: #2c5c6936;
  width: 40px;
  right: 0px;
  transform: translate(0, -50%);
  overflow: visible;
  height: fit-content;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #2c5c69;
  border-top: 1px solid #2c5c69;
  border-bottom: 1px solid #2c5c69;
  backdrop-filter: blur(5px);
  top: 50%;
  z-index: 6;

  /* right: -20px; */
  /* padding:4px; */
  /* margin-right:2px */
}

.offsetToolbar .toolbarTitle {
  cursor: wait;
  position: absolute;
  writing-mode: tb-rl;
  top: 50%;
  left: 0px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%) rotate(-180deg);
  /* transform: translate(-50%, -50%); */
  font-size: 14px;
  /* line-height:20px; */
  font-weight: 600px;
  color: black
}

.offsetToolbar .toolbarButtons {
  display: normal;
  opacity: 0;
  pointer-events: none;
}

.offsetToolbar:hover {
  min-height: 160px;
  max-height: unset;
  height: max-content;
  background: #ffffffB3;
  border-left: 2px solid #2c5c69;
  border-top: 2px solid #2c5c69;
  border-bottom: 2px solid #2c5c69;
  overflow: visible;
  width: 55px;
  padding-left: 5px;
  right: 0px;
  transition: .25s ease-in-out;
}

.offsetToolbar.Open {
  min-height: 160px;
  height: fit-content;
  background: #ffffffB3;
  border-left: 2px solid #2c5c69;
  border-top: 2px solid #2c5c69;
  border-bottom: 2px solid #2c5c69;
  overflow: visible;
  width: 60px;
  padding-left: 5px;
  right: 0px;
  transition: .35s ease-in-out;
}

.offsetToolbar.Open .toolbarTitle {
  cursor: none;
  opacity: 0;
  transition: opacity .5s linear;
}

.offsetToolbar.Open .toolbarButtons {
  display: normal !important;
  opacity: 1;
  transition: opacity 1s linear;
  pointer-events: auto;
}

.offsetToolbar:hover .toolbarTitle {
  cursor: none;
  opacity: 0;
  transition: opacity .5s linear;
}

.offsetToolbar:hover .toolbarButtons {
  display: normal !important;
  opacity: 1;
  transition: opacity 1s linear;
  pointer-events: auto;
}

.offsetToolbar li {
  width: fit-content;
  /* max-height: 40px; */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.offsetToolbar li span {
  display: none;
  position: absolute;
  right: 70px;
  transform: translateY(10%);
  width: max-content;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
  align-self: center;
  border-radius: 8px;
  overflow: auto;
  font-size: 14px;
}

.offsetToolbar li:hover span {
  display: inline-flex;
  color: #fff;
}

.schematicTitleBar {
  position: absolute;
  border-radius: 4px;
  height: 35px;
  width: 100%;
  color: white;
  background-color: #2c5c69 !important;
  border: 1px solid #00e29f;
}

.schematicTitle {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500px;
  margin-left: 10px;
  vertical-align: middle
}

.plotNavigation {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 40px;
  width: 45px;
  text-align: center;
  justify-content: center;
}

.singleDirectionalPlot {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 215px;
  /* width:calc(20% - 40px); */
}

.singleDirectionalPlot .Offset {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 125px;
  width: 20%;
}

.tdaOverlay {
  width: 100%;
  top: 0px;
  position: absolute !important;
  /* background-color: #00e29f20; */
  z-index: 10
}

.overlayToolbox {
  display: block;
  width: 50px;
  top: 28px;
  right: 15px;
  background-color: #ffffff20;
  position: absolute !important;
}

.overlayCompareTitle {
  position: absolute;
  top: 1px;
  /* transform: translate(-50%, 0%); */
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 600 !important;
  width: 300px;
  height: 1.4em;
  text-overflow: ellipsis;
  overflow: hidden;
}


.singleMudWeightPlot {
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;
  /* min-width:125px; */
  width: 28%
}

.singleWellboreDiagramPlot {
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  min-width: 140px;
  width: 32%
}

.singleFormationPlot {
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;
  min-width: 120px;
  width: 18%
}

.tdaViewer {
  justify-content: left;
  position: relative;
  border-radius: 4px;
  color: #000000;
  /* border: 1px solid rgba(0, 0, 0, 0.775);
  box-shadow: 0 0 3px 1px #2c5c69; */
  margin-top: 0px;
  background: white;
  padding-top: 5px;
  padding-bottom: 0px;
  min-height: 600px
}

.tdaSchematics {
  display: flex;
  justify-content: left;
  position: relative;
  margin-left: 30px;
  width: calc(100% - 18px)
}

.DepthRangeText {
  color: #000000;
  font-size: 20px;
  font-weight: 600
}