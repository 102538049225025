.benchMarkControlContainer {
  display: inline-block;
  border: 1px solid var(--primary);
}


.benchMarkControlToolbar {
  min-height: 150px;
  position: fixed;
  justify-content: space-around;
  transition: .35s ease-in-out;
  background: #2c5c69;
  width: 45px;
  height: fit-content;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid var(--success);
  border-top: 1px solid var(--success);
  border-bottom: 1px solid var(--success);
  bottom: 45vh;
  z-index: 100;
  right: -2px;
}

.benchMarkControlToolbar:hover>.closedTitle {
  color: var(--success)
}

.benchMarkDrillDownToolbar {
  min-height: 150px;
  position: fixed;
  justify-content: space-around;
  transition: .35s ease-in-out;
  background: #2c5c69;
  width: 45px;
  height: fit-content;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid var(--success);
  border-top: 1px solid var(--success);
  border-bottom: 1px solid var(--success);
  bottom: calc(45vh - 175px);
  z-index: 100;
  right: -2px;
}

.benchMarkDrillDownToolbar:hover>.closedTitle {
  color: var(--success)
}

.closedTitle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-left: 5px;
  writing-mode: vertical-lr;
  transform: translateY(-50%) rotate(-180deg);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: white;
  z-index: 20
}

.benchMarkToolbarContainer {
  position: relative;
  right: 0;
  backdrop-filter: blur(2px);
  background: #3e3e3eD6;
  color: white;
  z-index: 0;
  padding-right: 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.showToolbar {
  position: fixed;
  justify-content: space-around;
  transition: .35s ease-in-out;
  background: #3e3e3e00 !important;
  width: max-content;
  min-width: 200px;
  right: 0;
  padding: 4px;
  padding-left: 40px;
  border: none !important;
  visibility: visible !important;
  border-left: 1px solid var(--primary);
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: 110;
}

.showToolbar:hover>.CloseBtn {
  display: block;
  padding-right: 30px;
  cursor: pointer;
  z-index: 100
}

.viewedToolbarTitle {
  width: 120px;
  position: relative;
  left: 15px;
  padding: 3px;
  transition: .35s ease-in-out;
  margin-right: auto;
  margin-bottom: 2px;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 800 !important;
  /* text-decoration: underline; */
}

.viewedToolbar {
  color: white;
  margin-right: 18px;
  padding-right: 3px;
  min-width: 295px;
  max-width: 40%;
  width: fit-content;
  z-index: 100;
  min-height: 230px;
  max-height: 230px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 10px;
  margin-bottom: 2px;
}


.viewedToolbar::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

.viewedToolbar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
}

.viewedToolbar::-webkit-scrollbar-track-piece,
.viewedToolbar::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}

.benchmarkToggleList {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  margin-right: 5px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-radius: 4px;

}



/* .showToolbar .benchMarkTabs {
  position: absolute;
  left: 0px;
  top: 5px; */
/* transform: translateY(-50%); */
/* display: block; */
/* } */


.benchMarkTabs {
  position: absolute;
  left: -10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  padding-right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  z-index: 10;
  backdrop-filter: blur(2px);
  background: #3e3e3eD6;
  list-style: none
}

.benchMarkTabs li {
  width: fit-content;
}

.benchMarkTabs li span {
  display: none;
  position: absolute;
  right: 60px;
  transform: translateY(40%);
  width: max-content;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
  align-self: center;
  border-radius: 8px;
  overflow: auto;
  font-size: 14px;
}

.benchMarkTabs li:hover span {
  display: inline-flex;
  color: #fff;
}

.benchmarkLayout {
  position: relative;
  display: block;
  width: 100%
}

.benchmarkKPIContainer {
  position: relative;
  width: calc(100% - 40px);
  margin-right: auto;
  border: 3px solid var(--primary);
  border-radius: 5px;
  min-height: 400px;
  max-height: 850px;
  margin-bottom: 5px;
}

.benchmarkKPIContainer .noBorder {
  border: unset !important;

}

.benchmarkSupportContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: calc(0%);
  min-height: 400px;
  max-height: 850px;
  transition: .35s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.showContainer {
  margin-left: 10%;
  padding-top: 20px;
  transition: .35s ease-in-out;
  width: calc(90%) !important;
  border: 1px solid var(--primary);
  backdrop-filter: blur(10px);
  background: #e4e4e4de;
  border-radius: 3px;
  opacity: 100;
  pointer-events: all;
}

.benchmarkSupportContainer:hover>.CloseBtn {
  display: block;
  padding-right: 30px;
  cursor: pointer;
  z-index: 100
}

.benchmarkKPIRow {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%
}

.benchmarkScatterPlotContainer {
  position: relative;
  width: calc(25%);
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
}

.benchmarkPlotContainer {
  position: relative;
  width: calc(80%);
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
}

.benchmarkBoxPlotContainer {
  position: relative;
  width: calc(15%);
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
}

.benchmarkSuccessPlotContainer {
  position: relative;
  width: calc(25%);
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
}



.detailLevelSelection {
  position: absolute;
  top: -2px;
  right: 5px;
  width: 200px;
  z-index: 100
}

.goalMetric {
  counter-increment: grid-item;
  text-align: center;
  border: 2px solid var(--primary);
  border-radius: 4px;
  margin: 3px;
  padding: 1px 8px;
  backdrop-filter: blur(2px);
  background: #e4e4e41e;
  cursor: default;
  justify-content: center;
}

.clickableMetric {
  cursor: pointer;
}

.clickableMetric:hover {
  border: 2px solid var(--success);
  background: #00e29e5b;

}

.groupMetric {
  position: static;
  cursor: pointer;
}

.groupMetric:hover {
  border: 2px solid var(--success);
  background: #e4e4e473;
  overflow: visible
}

.groupDetailsContainer {
  position: fixed;
  pointer-events: none;
  padding: 5px;
  display: none;
  bottom: 5px;
  left: calc(0% + 350px);
  width: fit-content;
  height: fit-content;
  min-width: 200px;
  min-height: 120px;
  background: #e4e4e4e5;
  border: 1px solid #4e4d4dce;
  backdrop-filter: blur(2px);
  border-radius: 4px;
  z-index: 10
}

.groupMetric:hover+.groupDetailsContainer {
  display: flex;
}

.groupManagerDiv {
  display: grid;
  column-gap: 1rem;
  position: relative;
  counter-reset: grid-items;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(90px, 1fr));
  overflow-y: auto;
  max-height: calc(450px);
  margin-bottom: 5px;
}

.groupManagerDiv::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

.groupManagerDiv::-webkit-scrollbar-thumb {
  background: rgba(112, 112, 112, 0.95);
  border-radius: 10px;
}

.groupManagerDiv::-webkit-scrollbar-track-piece,
.groupManagerDiv::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}

.groupControlsContainer {
  position: absolute;
  min-width: 400px;
  max-width: 550px;
  width: '50%';
  /* height: 10px; */
  right: 5%;
  bottom: 5px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  z-index: 16;
}

.groupControlsContainer:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.openGroupControls {
  background: rgb(202, 202, 202);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid #0a2d4779;
  transition: opacity .3s ease-in, transform .2s ease-in, height .2s ease-in;
  opacity: 1;
  display: block;
  color: black !important;
  visibility: visible;
  overflow: visible;
  transform-origin: bottom;
  height: fit-content;
  transform: scaleY(1);
  /* min-height: 90%; */

}