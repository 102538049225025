.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

media-print {
  body {
    margin: 0;
    padding: 0 !important;
    min-width: 768px;
  }
  .container {
    width: auto;
    min-width: 750px;
  }
}