.card-tasks .card-header .title {
  margin-right: 24px;
}

.cardHolder {
  position: relative;
}


.chartLegends {
  padding: 0 15px;
  margin-right: calc(2%);
}

.legendColor {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff3b;
  margin-right: 5px;
}

.legendColor::before {
  content: "e";
  color: #f0f8ff00;
}

.sideMemo {
  font-size: 30px;
  height: 675px;
  position: absolute;
  z-index: 1000;
  right: -25px;
  width: 61px;
  padding-left: 5px;
}

.memoItem {
  font-size: 12px;
  border: 1px solid #9c9c9c;
  width: 27px;
  border-radius: 67px;
  line-height: 25px;
  background: #565656;
}

.popOverBody {
  color: black;
}

// .main-panel::after, .main-panel::before{
//   clear: both;
// } 



.scaleItem {
  //padding-bottom: 39%;
  border-top: 1px solid #989a9c;
  font-family: monospace;
  white-space: nowrap;
  margin-left: 2px;
  text-overflow: ellipsis;
  font-size: 11px;
  line-height: 11px;

}

.scaleItem:last-child {
  padding-bottom: 0;
  border-left: none;
}

.commentMemoSection {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;

}

$commentLineHeight: 16px;

.commentMemoItem {
  // border-bottom: 1px solid #d3d2d2;

  display: flex;
  line-height: $commentLineHeight;
}

.commentMemoItem:last-child {
  border: none;
}

.commentTime {
  position: relative;
  width: 35px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: $commentLineHeight;
  color: rgb(59, 59, 59);
  padding-right: 2px;
}

.commentTime:after {
  content: " ";
  position: absolute;
  height: calc(100% - 15px);
  left: 45%;
  top: 15px;
  /* border-top: 1px solid #50000f; */
  background: #c7c7c7;
  width: 1px;
}

.commentMemoItem:last-child .commentTime:after {

  width: 0px;

}

.commentArea {
  border-bottom: 1px solid #d3d2d2;
  padding-left: 2px;
  margin-bottom: 5px;
  width: calc(100% - 40px);
}

.commentMemoItem:last-child .commentArea {
  border: none;
}

.noshow {
  visibility: hidden;
}


/* this is the bottom part of the the traces */

.bottomSection {
  position: fixed;
  bottom: 0;
  // width: calc(100% - 140px);
  background: #3e3e3eee;
  height: 30px;
  z-index: 1003;
  transition: .5s ease-in-out;
  border-top: 2px solid #a0a0a0;
  box-shadow: 0 0 2px 0px #00e29f;
}

.hover:hover {
  height: 50px;
  z-index: 1003;
  transition: .5s ease-in-out;
  border-top: 2px solid #a0a0a0;
  box-shadow: 0 0 18px 0px #00e29f;
}



.titleBar {
  background-color: #2c5c69;
  line-height: 20px;
}

.bottomSection .mainSection {
  display: flex;
  height: calc(100% - 60px);
  overflow: hidden;
}

.mainSection .controls,
.mainSection .info {
  width: 22%;
  border: 1px solid #5a5a5a;
  padding: 0 15px;
}

.mainSection .mainChart {
  width: 60%;
  border: 1px solid #5a5a5a;
  border-right: none;
  border-left: none;
}

.sideArea {
  max-height: 100vh;
}

.seperator {
  margin: 5px 0;
}

.dataTransferItem {
  margin-left: 10px;
  font-size: 22px;
  color: #2c5c69;
  padding: 2px 2px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #eeeeee00;
}

.dataTransferItem:hover {
  background-color: #00e29fD6;
  border-color: #00e29f;
  border: 1px solid #00e29f;
  color: #2c5c69;
  border-radius: 5px;
  padding: 2px 2px;
}

.activityText {
  width: 95%;
  margin-top: 20px;
  font-size: 15px;
}

.infoItem {
  width: 50%;
  margin: 15px auto;
  background: #2d2d2d;
  padding: 5px;
  border-radius: 5px;
}

.controlItem {
  margin-bottom: 15px;
}

.infoItem.buttons {
  display: flex;
  background: none;
}

.infoItem.buttons>a {
  padding: 0;
  margin: auto;
}

.titleBar>p {
  margin: 0;
}



.commentsList {
  position: absolute;
  top: 0;
}

.commentWrapper {
  position: relative;
}

.commentItem {
  position: absolute;
  width: 200px;
  background: #929292;
  min-height: 50px;
  overflow: hidden;
  border: 1px solid #cacaca;
  padding: 2px;
}

.displayNone {
  display: none;
}

.commentContent {
  padding-top: 20px;
  overflow-y: auto;
  max-height: 100px;
  font-size: 10px;
}

.commentContent::-webkit-scrollbar {
  width: 5px;
}

.commentContent::-webkit-scrollbar-track {
  background: #ddd;
}

.commentContent::-webkit-scrollbar-thumb {
  background: rgba(172, 172, 172, 0.5);
}

.closeBtn {
  position: absolute;
  right: 5px;
  cursor: pointer;
  padding: 2px 5px;
  background: #f9f9f914;
}

.closeBtn:hover {
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
}