.rigHolderContainer{
  background: #F2F6F8;
  box-shadow: 0px 3px 8px rgba(34, 82, 94, 0.1);
  border-radius: 8px;
  height: 70px;
  margin-left: 10px;
  display:flex;
  margin-right:10px;
  width: calc(100% - 300px)
}

.rigNavOptionsContainer {
  position: relative;
  flex-direction: column;
  flex-basis: 100%;
  width: 40px;
  max-width: 50px;
  /* text-align: center; */
  height: 70px;
  justify-content: center;
  margin-left:2px;
  margin-right:2px;
  border: 1px solid #2c5c6900;
  overflow:visible !important;
  display:flex;
}

.rigNavContextContainer.show {
  display: block;
  justify-content: left;
  align-items: left;
  position: absolute;
  max-width: 55px;
}

.rigNavContextMenu {
  min-width: 130px;
  min-height: 100px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  padding: 4px 2px;
  /* width: 100%; */
  height: fit-content;
  left: 320px;
  top: 65px;
  display: none;
  z-index: 10000000;
  border-radius: 5px;
  border: 2px solid #2c5c69;
  font-size: 12px;
}

.rigNavContextMenu.show {
  display: block;
  justify-content: left;
  align-items: left;
}


.validationContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  /* width: 100%;
  max-width: 100%;
  overflow: inherit */
}

.validationContainer .validatorName {
  width: 40%;
  max-width: 400px;
  min-width: 200px;
  margin-left: 15px;
  margin-right: 15px;
}

.validationContainer .validationDate {
  width: 30%;
  max-width: 300px;
  min-width: 180px;
  margin-left: 15px;
  margin-right: 15px;
}

.validationContainer .validateItem {
  width: 10%;
  max-width: 100px;
  min-width: 40px;
  margin-left: 15px;
  margin-right: 15px;
  align-self: center;
}

.proTip {
  position: absolute;
  width: 100%;
  z-index: 1004;
  justify-content: center;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
}

.proTip .proTipSection {
  position: relative;
  text-align: center;
  display: inline-flex;
  max-width: clamp(350px, 60%, 850px);
  background: #FFFFFFD6;
  max-height: 200px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1004;
  padding: 10px;
  border-top: 1px solid #00e29f;
  box-shadow: 0 0 2px 0px #2c5c69;
  border-radius: 8px;
}

.proTip .warning {
  border-top: 3px solid #b30211 !important;
  box-shadow: 0 0 5px 0px #ea4343 !important;
  background: #FFFFFF;
}

.proTip .success {
  border-top: 3px solid #00e29f !important;
  box-shadow: 0 0 5px 0px #2c5c69 !important;
  background: #FFFFFF;
}

.proTip .primary {
  border-top: 3px solid #2c5c69 !important;
  box-shadow: 0 0px 5px 1px #2c5c69 !important;
  background: #FFFFFF;
}

.proTipSection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.proTipSection .tipIconwarning {
  color: #b30211 !important;
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 30px;
  margin-left: 8px;
  margin-right: 10px;
  text-align: center !important;
}

.proTipSection .tipTitle {
  align-self: center;
  justify-content: center;
  position: relative;
  padding: 3px;
  min-width: 75px;
  max-width: 150px;
  align-self: center;
  font-weight: 550;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.proTipSection .tipDescription {
  align-self: center;
  max-width: 550px;
  padding: 4px;
  margin: 4px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.proTipSection .tipLink {
  align-self: center;
  max-width: 150px;
  margin: 5px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.proTipSection .proTipCloseBtn {
  position: absolute;
  right: 2px;
  top: 0px;
  font-size: 16px;
  color: #ff5600;
  z-index: 12005;
  padding: 5px;
  display: none;
}

.proTipSection:hover>.proTipCloseBtn {
  display: block;
  cursor: pointer
}

.backgroundTaskParent {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-Left: auto;
  text-align: right;
  z-index: 2004;
  width: 350px;
}

.backgroundTaskParent .backgroundTaskBottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
  background: #3e3e3eD6;
  height: 10px;
  z-index: 2004;
  transition: .5s ease-in-out;
  border-top: 2px solid #2c5c69;
  box-shadow: 0 0 9px 0px #2c5c69;
}

.backgroundTaskHover:hover {
  height: 35px;
  width: 300px;
  z-index: 2004;
  transition: .5s ease-in-out;
  box-shadow: 0 0 18px 0px #00e29f;
}

.backgroundTaskslideOut {
  display: table;
  width: 350px !important;
  background: #FFFFFFD6 !important;
}

.backgroundTaskParent .taskTitle {
  background: #3e3e3eD6;
  align-self: center;
  justify-content: center;
  position: relative;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #ffffff;
  text-align: center !important;
}

.wellErrorDashboardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  position: relative;
  justify-items: center;
  counter-reset: grid-items;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  margin-bottom: 5px;
}

.wellErrorDashboardContainer::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

.wellErrorDashboardContainer::-webkit-scrollbar-thumb {
  background: rgba(112, 112, 112, 0.95);
  border-radius: 10px;
}

.wellErrorDashboardContainer::-webkit-scrollbar-track-piece,
.wellErrorDashboardContainer::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}

.InsightContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: calc(0%);
  min-height: 400px;
  max-height: 850px;
  transition: .35s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.taskItem {
  display: table-row;
  align-self: center;
  height: 30px;
  padding-left: 10px;

}

.taskItem .taskRow {
  display: flex
}

.taskItem .taskRow .taskIcon {
  margin-left: 6px;
  font-size: 16px;
  color: #323232 !important;
}

.taskItem .taskRow .taskText {
  margin-left: 6px;
  font-weight: 200;
  font-size: 14px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #323232 !important;
  text-align: left !important;
}

.taskItem .i {
  margin-left: 30px;
}

.wellTableInfoIcon {
  position: relative;
}

.activeHeaderWellName {
  position: relative;
  text-align: left;
  display: inline-flex;
}

.wellHealthReportIcon {
  position: absolute;
  width: 30px;
  right: -60px;
  top: 24%;
  z-index: 1004;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.wellHealthReport {
  position: fixed;
  width: 90vw;
  min-width: 950px;
  left: 50%;
  transform: translate(-40%, 0%);
  z-index: 2004;
  justify-content: center;
  text-align: center;
}

.wellControllersContainer {
  position: fixed;
  width: fit-content;
  z-index: 100;
  justify-content: center;
  text-align: center;
  pointer-events: none;
}

.wellHealthReportSection {
  position: relative;
  min-width: 350px;
  /* width: fit-content; */
  max-width: 80vw;
  background: #FFFFFF;
  color: black;
  max-height: 700px;
  min-height: 55px;
  z-index: 100;
  border: 2px solid #2c5c69;
  box-shadow: 0 0 2px 0px #00e29f;
  border-radius: 8px;
  padding-bottom: 8px;
  opacity: 0;
  transition: opacity 0.5s linear;
  pointer-events: none;
  overflow: auto;
}

.wellHealthReportSection.show {
  opacity: 1;
  /* transition: opacity 1s linear; */
  pointer-events: auto;
}

.wellHealthReportSection .wellHealthReportCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 12005;
  padding: 5px;
  display: none;
}

.wellHealthReportSection:hover>.wellHealthReportCloseBtn {
  display: block;
  cursor: pointer
}

.wellHealthReportSection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellHealthReportSection .wellName {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellHealthIcon {
  position: absolute;
  width: 30px;
  right: -30px;
  top: 24%;
  z-index: 1004;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.wellHealth {
  /* position: fixed;
  max-width: 500px;
  min-width: 250px;
  width: 25%;
  right: -280px;
  z-index: 14;
  justify-content: center;
  text-align: center; */
  left: 200px;
  position: fixed;
  width: fit-content;
  z-index: 100;
  justify-content: center;
  text-align: center;
}

.wellHealth .wellHealthSection {
  position: relative;
  min-width: 350px;
  background: #ffffff;
  max-height: 400px;
  z-index: 12004;
  border-top: 1px solid #00e29f;
  box-shadow: 0 0 2px 0px #2c5c69;
  border-radius: 8px;
}

.wellHealthSection .wellHealthCloseBtn {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 16px;
  color: #ff5600;
  z-index: 12005;
  padding: 5px;
  display: none;
}

.wellHealthSection:hover>.wellHealthCloseBtn {
  display: block;
  cursor: pointer
}

.wellHealthSection .tipIcon {
  align-self: center;
  text-transform: uppercase;
  width: 35px;
  font-weight: 400;
  font-size: 34px;
  margin-left: 8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellHealthSection .wellName {
  align-self: center;
  justify-content: center;
  position: relative;
  margin: 2px;
  min-width: 75px;
  align-self: center;
  font-weight: 400;
  font-size: 20px;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.wellStatusBar {
  width: 100%;
  margin-right: 0px;
  border-bottom: 1px solid #0a2d47;
}

.wellStatusRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 15px;
  justify-content: space-between;
}

.wellStatusMetric {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
}

.wellStatusMetricLabel {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 13px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.wellStatusMetricValue {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  text-align: center !important;
  color: #000000
}

.formRowContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 500px;
  margin-left: 12px;
}

.dateRangeContainer {
  max-width: 250px;
  min-width: 120px;
  margin-right: 15px;
  margin-left: 15px;
  color: #323232
}

.activeEvent {
  width: clamp(300px, 20%, 450px);
  min-width: 300px;
  background: #B1FCE3;
  height: 68px;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  box-sizing: border-box;
  color: #333;
  margin-right: 10px;
  display: inline-block;
  /* overflow:hidden; */
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  border: 1px solid #2c5c69;
}

.activeEvent:hover {
  border: 2px solid #00e29f;
  background: white;
}

.dvdSelectContainer {
  bottom: -10px;
  position: absolute;
  display: flex;
}

.dvdSegmentSelect {
  width: clamp(260px, 50%, 320px);
  min-width: 260px;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 6px;
  z-index: 3;
  cursor: pointer;
  position: relative;
}

.dvdIntervalSelect {
  width: clamp(200px, 40%, 280px);
  min-width: 200px;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 6px;
  z-index: 3;
  cursor: pointer;
  position: relative;
}

.kpiGroupMetricMax {
  display: flex;
  flex: no-wrap;
  width: 22%;
  text-align: center !important;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 6px;
  text-align: center !important;
  color: #000000
}


.formRowContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 500px;
  margin-left: 12px;
}

.dateRangeContainer {
  max-width: 250px;
  min-width: 120px;
  margin-right: 15px;
  margin-left: 15px;
  color: #323232
}

.feedBackModal {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  z-index: 1000;
  top: 200px;
  width: 55%;
  left: 22.5%;
  /* height: 70%; */
  min-width: 400px;
  height: fit-content;
  max-height: 800px;
  padding: 20px;
  background: #3e3e3eD6;
  flex-grow: 1
}

.feedBackModal .quickSelect {
  width: 75%;
  /* height: 70%; */
  min-width: 600px;
}

.multiReleasePosition {
  position: absolute;
  right: 2px;
  bottom: 0px;
  font-size: 12px;
  z-index: 1205;
  padding: 5px;
}

.rightSwipePosition {
  position: absolute;
  /* width: 50%; */
  left: calc(50% + 280px);
  top: calc(30% + 120px);
  transform: translate(-50%, 0%);
  z-index: 201
}

.leftSwipePosition {
  position: absolute;
  /* width: 50%; */
  left: calc(50% - 290px);
  top: calc(30% + 120px);
  transform: translate(-50%, 0%);
  z-index: 201
}

.CloseBtn {
  position: absolute;
  right: 2px;
  top: 1px;
  font-size: 16px;
  color: #ff5600;
  padding: 5px;
  display: none;
}

.expandBtn {
  position: absolute;
  right: 2px;
  bottom: clamp(60px, 75px, 120px);
  font-size: 16px;
  color: #0a2d47;
  z-index: 1005;
  padding: 5px;
  display: block;
}

.expandBtn:hover {
  color: #00e29f;

}

.expandedDetails {
  width: 100%;
  opacity: 0;
  transition: .5s ease-in-out;
}

.expandedDetails.detailsExpanded {
  transition: .5s ease-in-out;
  opacity: 1;
}

/* .feedBackModal:hover>.CloseBtn {
  display: block;
  cursor: pointer
} */

.feedBackModalBody {
  padding: 10px;
  margin: 2px;
  color: black;
  /* margin: 20px; */
  background: white;
  border-radius: 4px;
}

.controlsContainerTitle {
  position: absolute;
  top: -15px;
  width: 100%;
  color: black;
  font-size: 0.7625rem;
  line-height: 1.4em;
  font-weight: 600 !important;
  overflow: hidden;
}

.controlsContainerTitleDark {
  position: absolute;
  top: -15px;
  width: 100%;
  color: #e3e3e3;
  font-size: 0.7625rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.lastUpdateDateText {
  color: black;
  font-size: 0.6625rem;
  line-height: 1.2em;
  font-weight: 500 !important
}

.lastUpdateDateTextDark {
  color: #e3e3e3;
  font-size: 0.6625rem;
  line-height: 1.2em;
  font-weight: 500 !important
}

.verticalLoader {
  position: absolute;
  justify-content: 'center';
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  height: 100%;
}

/* Sizing for Filtering, Date Ranges, Selections, Drop Downs, Multi Drop Downs */
.controlSelectionRow {
  position: relative;
  display: flex;
  width: 100%;
  overflow-wrap: wrap
}

.controlSelectionRow:hover>.CloseBtn {
  display: block;
  cursor: pointer;
  z-index: +1;
  color: #ff5600;
}

.controlEditDepthTimeBox {
  min-width: 160px;
  width: 180px !important;
  margin-left: 10px;
  margin-right: 10px;
}

.controlDateOnlyBox {
  min-width: 120px;
  width: 140px;
  margin-left: 10px;
  margin-right: 10px;
}
.controlFilterBox {
  min-width: 180px;
  width: 200px;
  margin-left: 15px;
  margin-right: 15px;
}

.controlSlimBox {
  min-width: 120px;
  width: 140px;
  margin-left: 10px;
  margin-right: 10px;
}

.viewSettingsBox {
  min-width: 280px;
  width: 300px;
  margin-left: 15px;
  margin-right: 15px;
}

.activeControllerContainer {
  position: absolute;
  padding: 2px 2px 4px 2px;
  align-self: center;
  margin-left: auto;
  top: 5px;
  right: 5px;
  z-index: 10;
  display: flex;
  width: fit-content;
  min-width: 160px;
  height: fit-content;
  border-radius: 5px;
  color: black;
  border: 2px solid #00e29f !important;
}


.ControlsToolboxTitle {
  position: absolute;
  top: 1px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0%);
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.ControlsToolboxContainer {
  padding-top: 15px;
  padding-right: 55px;
  padding-left: 15px;
  justify-content: right;
  align-self: center;
  margin-left: auto;
  top: -1px;
  right: -1px;
  display: flex;
  height: fit-content;
  width: fit-content;
  position: relative;
  overflow-x: hidden;
  border-radius: 5px;
  overflow-y: hidden;
  border: 2px solid #2c5c6900 !important;
}

.ControlsToolboxContainer:hover {
  /* box-shadow: 0 0 9px 0px #2c5c69; */
  /* border: 2px solid #2c5c6940 !important; */
}

.ControlsToolboxContainer .ControlsToolboxSubGroup {
  top: 1px;
  right: 120px;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.WhiteCardContainer {
  position: relative;
  width: 100%;
  border-radius: 5px;
  color: black;
  background-color: white;
  border: 2px solid #2c5c69 !important;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 5px
}

.WhiteCardContainer .label {
  font-size: 0.75rem;
  margin-bottom: .1rem !important;
}

.CardCollapseControl {
  position: absolute;
  top: 15px;
  right: 5px;
}

.selectLabel {
  position: relative;
  /* text-align: center; */
  white-space: nowrap;
  overflow: visible;
  color: black;
  font-size: 0.875rem;
  margin-bottom: 0.05rem;
  margin-left: 0.8rem;
  font-weight: 600 !important
}

.unitLabel {
  position: relative;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  color: black;
  font-size: 0.75rem;
  margin-left: 4px;
  margin-right: 8px;
  margin-top: 0.2em;
  line-height: 1.2em;
  font-weight: 600 !important
}

.buttonLabel {
  position: relative;
  text-align: center;
  white-space: pre-line;
  overflow: visible;
  color: black;
  font-size: 0.75rem;
  line-height: 1.3em;
  font-weight: 600 !important;
  pointer-events: none;
}

.buttonLabel.modalButton {
  color: white !important;
}

.traceRangeBox {
  min-width: 70px;
  width: 90px;
  margin-left: 10px;
  margin-right: 10px;
}

.opsDetailsModal {
  position: fixed;
  display: inline-block;
  border-radius: 5px;
  z-index: 100;
  bottom: 4%;
  width: fit-content;
  right: 20px;
  min-width: 300px;
  max-width: 400px;
  height: fit-content;
  max-height: 80%;
  padding: 5px;
  background: white;
  border: 5px solid #3e3e3eD6;
  /* border-color: #3e3e3eD6; */
  flex-grow: 1;
  box-shadow: 3px 3px 5px 0px #2c5c69;
}


.opsDetailsModal.inModal {
  right: -280px !important;
  bottom: 100px !important;
  overflow: hidden;
}

.opsDetailsModal:hover>.CloseBtn {
  display: block;
  cursor: pointer;
  z-index: +1;
  color: #ff5600;
}

.chat {
  display: flex;
  flex-direction: column;
  font-size: 14.5px;
  overflow-y: auto;
  height: calc(100% - 100px);
  box-sizing: border-box;
  line-height: 1.4;
}


.draggable {
  top: auto !important;
  left: auto !important;
}

.tableTitleBar {
  position: absolute;
  height: 60px;
  top: -50px;
  width: 100%;
  margin-left: 5px
}

.tableHeaderNotes {
  position: absolute;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.chatMessage {
  flex-grow: 1;
  box-shadow: 0 0 5px #999;
  background: white;
  height: 100vh;
  position: fixed;
  right: 0;
  bottom: 0;
  width: clamp(250px, 30%, 450px);
  transition: 0.3s;
}

.reactTableContainer {
  /* user-select: none; */
  position: relative;
  height: fit-content;
  width: fit-content;
  margin: auto;
  max-width: 100%
}

.tableTitleBar {
  position: absolute;
  height: 60px;
  top: -50px;
  width: 100%;
  margin-left: 5px
}

.tableHeaderNotes {
  position: absolute;
  width: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.taskPosition {
  position: absolute;
  /* width: 50%; */
  left: 50%;
  top: 25%;
  transform: translate(-50%, 0%);
  z-index: 1051
}

.taskCardContainer {
  height: 20em;
  width: 28em;
  background: rgba(202, 202, 202, 0.65);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  border: 2px solid #0a2d4779;
  transition: .5s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;
  counter-increment: grid-item;
  /* width: 100%; */
  max-width: 500px;
  min-width: 0;
}

.queMetricsGridBody {
  width: 100%;
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: space-evenly;
  column-gap: 10px;
  padding-right: 10px;
  line-height: 1.5
}

.taskExpanded {
  height: 35em;
  transition: .5s ease-in-out;
}

.taskCardContainer:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.taskCardContainer:hover>.expandBtn {
  display: block;
  cursor: pointer
}

.taskTitle {
  color: var(--black);
  font-size: 0.7125rem;
  margin-top: 0.25rem;
  margin-left: 0.35rem;
  line-height: 1.0em;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taskLabel {
  min-width: 55px;
  color: var(--black);
  font-size: 0.8125rem;
  margin-top: 0.25rem;
  margin-left: 0.35rem;
  line-height: 1.0em;
  font-weight: 400 !important;
}

.dateStamp {
  min-width: 55px;
  color: var(--black);
  font-size: 0.7125rem;
  margin-top: 0.15rem;
  margin-left: 0.15rem;
  line-height: 1.2em;
  font-weight: 400 !important;
}

.headerDetailSettings {
  display: grid;
  grid-auto-rows: 1fr;
  row-gap: 3px;
  max-height: 100px;
}

.modelFeatureRow {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  min-height: 30px;
  bottom: 5px;
  padding-right: 5px
}

.queMetricItem {
  display: block;
  width: fit-content;
  padding: 10px;
  text-align: center
}

.queMetricIcon {
  display: flex;
  height: 20px;
  justify-content: space-around;
  align-self: center;

}

.adaMetricTitle {
  padding-top: 0.45rem;
  text-align: center
}

.queMetricDetailValue {
  align-self: center;
  font-weight: 800;
  font-size: 35px !important;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  color: #00e29f;
  justify-content: center;
  margin-left: auto !important;
  margin-top: 4px;
}

.quePosition {
  display: flex;
  width: 100%;
  color: var(--black);
  font-size: 0.8125rem;
  margin-top: 0.35rem;
  margin-left: 0.35rem;
  line-height: 1.2em;
  font-weight: 600 !important;
}

.controlsTitleContainer {
  position: relative;
  width: 100%;
  top: 0px;
  min-height: 40px;
  background: #0a2d47;
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 26px;
  padding-top: 5px;
  padding-bottom: 0px;
}

.colorPickerClose {
  display: block;
  align-self: center;
  margin: auto;
  background-color: white;
  border: 1px solid black;
  border-left: unset;
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.queMetricsContainer {
  position: absolute;
  width: 100%;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-evenly;
  column-gap: 10px;
  z-index: 1005;
  bottom: 0px;
  left: 0px;
  /* height: 25%; */
  min-height: 60px;
  max-height: 150px;
  background: #0a2d47;
  color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 22px;
  padding-top: 2px;
  padding-bottom: 12px;
}

.queMetricsContainer.inBody {
  position: relative;
  width: 100%;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-evenly;
  column-gap: 10px;
  z-index: unset;
  background: transparent;
  min-height: 20px;
  max-height: 150px;
  color: #0a2d47;
  font-size: 18px;
  padding-top: 2px;
  padding-bottom: 0px;
  margin-bottom: -15px;
  margin-top: -5px
}

.refreshDate {
  opacity: 0
}

.refreshDatenoHover {
  opacity: 1 !important
}

.queMetricsContainer:hover>.refreshDate {
  opacity: 1
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

.smallIconMetricContainer {
  display: grid;
  position: relative;
  margin: auto;
  column-gap: .5rem;
  grid-auto-flow: column;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 15px
}

.smallIconMetric {
  text-align: center;
  display: flex;
  width: fit-content
}

.supportDetailsIcon {
  cursor: pointer !important;
  position: relative;
  display: flex;
  overflow: visible
}

.supportDetailsIcon:hover>.supportDetails {
  display: block
}

.supportDetails {
  position: absolute;
  top: 20px;
  left: 40px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  padding: 5px;
  width: 250px;
  border: 2px solid #0a2d4779;
  height: fit-content;
  z-index: 100;
  display: none
}


.supportDetails:hover> {
  display: block
}

.supportDetailsDarkMode {
  background: #3e3e3eD6 !important;
  border: 2px solid #00e29f !important;
}

.expandable {
  padding: 0px !important;
  width: fit-content !important;

}


.supportDetailsExpansion {
  position: relative;
  height: fit-content;
  width: 100%;
}

.supportDetailsRight {
  position: absolute;
  top: 40%;
  right: -20px;
  width: 20px;
  height: 40px;
  background: #0a2d47bf;

  transform: translate(0%, -50%);
  padding-top: 8px;
  padding-left: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid #0a2d4700;
}

.supportDetailsRight:hover {
  border: 2px solid #00e29f;
  border-left: 2px solid #0a2d4700;
  right: -25px;
  width: 25px;
  background: #0a2d47;
  padding-left: 6px;
  cursor: pointer;
}

.supportDetailsRight.isOpen {
  border-top: 2px solid #0a2d4700;
  right: -25px;
  width: 25px;
  padding-left: 6px;
}

.supportDetailsRight.isOpen:hover {
  border: 2px solid #ff5600;
  border-left: 2px solid #0a2d4700;
  right: -20px;
  width: 20px;
  padding-left: unset;
  background: #0a2d47;
}


.supportDetailsImageRight {
  position: absolute;
  top: 50%;
  width: 300px;
  right: -299px;
  height: 100%;
  max-height: 250px;
  transform: translate(0%, -50%);
  /* background: #0a2d47; */
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(8px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 2px solid #0a2d4779;
  border-left: 2px solid #0a2d4700;

}

.supportDetailsBottom {
  position: absolute;
  left: 50%;
  bottom: -20px;
  height: 20px;
  width: 40px;
  background: #0a2d47bf;
  text-align: center;
  transform: translate(-50%, 0%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid #0a2d4700;
}

.supportDetailsBottom:hover {
  border: 2px solid #00e29f;
  border-top: 2px solid #0a2d4700;
  bottom: -25px;
  height: 25px;
  background: #0a2d47;
  padding-top: 3px;
  cursor: pointer;
}

.supportDetailsBottom.isOpen {
  border-top: 2px solid #0a2d4700;
  bottom: -25px;
  height: 25px;
  padding-top: 3px;
}

.supportDetailsBottom.isOpen:hover {
  border: 2px solid #ff5600;
  border-top: 2px solid #0a2d4700;
  bottom: -20px;
  height: 20px;
  margin-top: -5px;
  padding-top: unset;
  background: #0a2d47;
  cursor: pointer;
}

.specSheetOpen {
  display: block !important
}

.tableSupportDetail {
  position: fixed;
  top: 370px;
  right: 40px;
  left: unset
}

.animoVersion {
  position: fixed;
  font-size: 12px;
  bottom: 8px;
  left: 10px;
  z-index: 6;
}

.showInsightContainer {
  margin-left: 90px;
  padding-top: 20px;
  transition: .35s ease-in-out;
  width: calc(100% - 100px) !important;
  border: 1px solid var(--primary);
  backdrop-filter: blur(10px);
  background: #e4e4e4de;
  max-height: calc(100% - 50px);
  border-radius: 3px;
  opacity: 100;
  pointer-events: all;
}

.InsightContainer:hover>.CloseBtn {
  display: block;
  padding-right: 30px;
  cursor: pointer;
  z-index: 100
}

.errorMetric {
  text-align: center;
  counter-increment: grid-item;
  /* display: inline-block; */
  border: 2px solid var(--primary);
  border-radius: 4px;
  margin: 3px;
  padding: 1px 8px;
  backdrop-filter: blur(2px);
  background: #e4e4e41e;
  cursor: default
}

.clickableMetric {
  cursor: pointer;
}

.clickableMetric:hover {
  border: 2px solid var(--success);
  background: #00e29e5b;
}

.errorManagerContainer {
  position: relative;
  margin-right: auto;
  border-radius: 5px;
  min-height: 400px;
  margin-bottom: 5px;
}