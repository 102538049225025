.welcomeContent {
    display: flex;
}

.mainContent {
    display: flex;
}

.leftArea {
    width: 55%;
}

.leftAreaMapOffset {
    width: 35%;
}

.topSection,
.mainBottomSection {
    display: flex;
    margin-top: 30px;
}

.mainBottomSection {
    position: relative;
    min-height: 600px;
}

.bottomSectionControls {
    top: -5px;
    position: absolute;
    display: flex;
    z-index: 10;
}

.rightArea {
    width: 45%;
}

.rightAreaMapOffset {
    width: 65%;
}

.topStats {
    position: relative;
    width: 50%;
}

.leftAreaMapOffset .topStats {
    width: 100%;
    ;
}

.leftAreaMapOffset .topStats:first-child {
    width: 0;
    overflow: hidden;
}



.leftAreaMapOffset .MetricApp {
    overflow: hidden;
}

.leftAreaMapOffset .metricItem {
    flex-basis: 100% !important;
}