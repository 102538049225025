.loaderMask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background: #ffffff9c;
}

.welcomeloaderMask {
  position: absolute;
  left: 0;
  top: 300px;
  width: 60%;
  height: 100vh;
  z-index: 200;
  background: #ffffff9c;
}

.singleloaderMask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  justify-content: center;
  background: #ffffff9c;
  text-align: center;
  border: 1px dashed #aaaaaa9c;
}

.singleloaderMask .CloseBtn {
  position: absolute;
  right: 2px;
  top: -1px;
  font-size: 16px;
  color: #ff5600;
  z-index: 12005;
  padding: 2px;
  display: none;
}

.singleloaderMask:hover>.CloseBtn {
  display: block;
  cursor: pointer
}

.div-chip-loader {
  /* display: inline-block; */
  position: relative;
  height: 40vh;
  max-height: 140px;
  top: 20%;
}

.div-ellipsis {
  /* display: inline-block; */
  position: absolute;
  left: 50%;
  top: 20%;
}

.div-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  z-index: 200;
  border-radius: 50%;
  background: #7f7f7f;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.div-ellipsis div:nth-child(1) {
  left: 8px;
  animation: div-ellipsis1 0.6s infinite;
}

.div-ellipsis div:nth-child(2) {
  left: 8px;
  animation: div-ellipsis2 0.6s infinite;
}

.div-ellipsis div:nth-child(3) {
  left: 32px;
  animation: div-ellipsis2 0.6s infinite;
}

.div-ellipsis div:nth-child(4) {
  left: 56px;
  animation: div-ellipsis3 0.6s infinite;
}

@keyframes div-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes div-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes div-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 50%;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #7f7f7f;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}