.paramMultiContainer {
  position: relative;
  padding-top: 5px;
  padding-bottom: 70px;
  background-color: transparent;
  align-self: center;
  min-height: 55px;
  width: 100%;
  position: relative
}

.paramMultiRow {
  position: relative;
  display: flex;
  background-color: transparent;
  align-self: center;
  min-height: 55px;
  width: 100%;
  position: relative
}

.paramMultiNavigation {
  width: 120px;
  /* height:fit-content */
}

.paramMultiFormation {
  width: 120px;
  /* height:fit-content */
}

.paramMultiChartArea {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  /* background-color:white; */
  position: relative;
  color: black;
  min-height: 70px;
}



.paramMultiChartLabels {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  text-align: center;
  color: black;
  align-self: center;
  z-index: 10;
}

.paramPreview {
  grid-auto-columns: minmax(100px, 200px);
}

.paramMultiLegend {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: black;
  align-self: center;
}


.underlayPlotContainer {
  position: absolute;
  margin-bottom: 30px;
  /* calc(100% - 60px); */
}
.overlayPlotContainer {
  position: absolute;
  z-index:10;
  margin-bottom: 30px;
  /* calc(100% - 60px); */
}
.formationOverlay {
  position: absolute;
  left: 30px;
  word-wrap: normal;
  min-width: 40px;
  z-index: 7;
  max-width: 100%;
  text-align: center
}


/* .formationSelectionContainer {
  position: absolute;
  top: 50%;
  height: 100%;
  width: 40px;
  background-color: #00e29f;
  left: 30px;
  min-width: 80px;
  z-index: 30;
} */

.formationSelectionContainer {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 40px;
  left: -10px;
  z-index:8;
}

.formationSelectionTitle {
  position: relative;
  width: fit-content;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  white-space: nowrap;
  top: 50%;
  left: -15px;
  font-weight: 600 !important;
  transform: translateY(-50%) rotate(-90deg);
}

.casingPlot {
  position: absolute;
  margin-bottom: 30px;
  /* calc(100% - 60px); */
}

.casingOverlay {
  position: absolute;
  bottom: -60px;
  left: 30px;
  z-index: 30;
}

.NoDataVerticaltext {
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 12;
  font-weight: 600;
  white-space: nowrap;
  transform: rotate(-90deg);
  transform-origin: right, top;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: right, top;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: right, top;
  position: absolute;
  left: 0%;
  top: 250px;
  color: black;
}
.NoDataHorizontalText {
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 12;
  font-weight: 600;
  white-space: nowrap;
  transform-origin: right, top;
  -ms-transform-origin: right, top;
  -webkit-transform-origin: right, top;
  position: absolute;
  left: 50%;
  top: 20px;
  color: black;
}
.directionalPlot {
  position: absolute;
  margin-bottom: 30px;
  margin-top: -20px;
  left: 55px;
  z-index: 4;
  width: 190px;
  /* calc(100% - 60px); */
}

.directionalPlot.sectionWashup {
  position: absolute;
  margin-bottom: 30px;
  margin-top: -2px;
  margin-left: auto;
  right: 55px !important;
  z-index: 4;
  width: 210px;
  /* calc(100% - 60px); */
}

.invisibleMask {
  /* filter: grayscale(100%); */
  background: rgba(255, 46, 46, 0.25);
  z-index: 4;

}


.legendName {
  cursor: default;
  background: transparent;
  align-self: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: black;
  padding-right: 10px;
  text-align: center !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: elipsis
}

.legendEntry {
  position: relative;
  height: 58px;
  min-width: 185px;
  width: fit-content;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-left: 10px;
  transition: .3s ease-in-out;
  border: 1px solid #56565600;
  z-index: 8;
}

.legendEntry .topRow {
  align-self: center;
  display: flex;
  width: fit-content;

}

.legendEntry .highlightMask {
  border: 1px solid #939600;
  border-radius: 5px;
  background: rgba(245, 255, 46, 0.25);
  z-index: 3;
}

.legendEntry .sourceMask {
  border: .5px solid #01463178;
  border-radius: 5px;
  box-shadow: 0 0 5px 0px rgb(62, 194, 154);
  background: rgba(62, 194, 54, 0.25);
  z-index: 4;
  overflow: hidden;
}
.legendEntry .noMask {
  border: .5px solid #01463178;
  border-radius: 5px;
  box-shadow: 0 0 0px 0px rgb(62, 194, 154);
  z-index: 4;
  overflow: hidden;
}

.noDataMask {
  display: flex;
  align-items: center;
  border: .5px solid red;
  /* padding: 5px;
  margin: 5px 0; */
  z-index: 4;
  background-color: #ffe6e6; /* Light red background for better visibility */
  color: red; /* Red text color */
}

.noDataMask::before {
  content: '⚠️'; /* Warning icon */
  margin-right: 8px;
}
.legendEntry:hover {
  cursor: default;
  background: transparent;
  box-shadow: 0 0 2px 0px #00e29f;
  border: 1px solid #565656;
  /* transition: .1s ease-in-out; */
  height: 58px;
  align-self: top;
}

.legendEntry:hover .legendName {
  align-self: top;
}
.legendEntry.loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width:100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.6); /* Light overlay background */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}



.spinner {
  width: 20px;
  height: 20px;
  left: 40px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6); 
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #2c5c69;
  animation: spin 1s ease-in-out infinite;
  z-index: 2; /* Spinner above overlay */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.legendActionsContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2c5c69;
  width: 40px;
  height: 15px;
  width: 100%;
  height: unset;
  background-color: #2c5c6900;
}

.legendEntry:hover .legendActionsContainer {
  opacity: 1;
  width: 100%;
  height: unset;
  background-color: #2c5c6900;
  transition: all .3s linear;
}

.legendActions {
  width: 100%;
  /* margin-top: -5px; */
  display: flex !important;
  opacity: 0;
  margin-bottom: 5px;
  transition: opacity .3s linear;
  justify-content: space-evenly;
}

.legendActions .btn-icon {
  height: 25px !important;
  min-width: 25px !important;
  width: 25px !important;
}

.legendEntry:hover .legendActions {
  opacity: 1;
  transition: opacity .3s linear;
}

.opControlsContainer {
  position: absolute;
  padding: 15px 20px 2px 20px;
  justify-content: righleftt;
  align-self: center;
  margin-left: auto;
  top: 75px;
  right: 5px;
  z-index: 10;
  display: flex;
  width: fit-content;
  min-width: 160px;
  overflow-x: hidden;
  border-radius: 5px;
  overflow-y: hidden;
  color: black;
  border: 2px solid #2c5c6900 !important;
}

.opControlsContainer:hover {
  box-shadow: 0 0 9px 0px #2c5c69;
  border: 2px solid #2c5c69 !important;
}

.plotControlsTitle {
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-top: -15px;
  margin-left: auto;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 600 !important
}

.plotControlsContainer {
  position: relative;
  padding: 15px 20px 2px 20px;
  justify-content: right;
  align-self: center;
  margin-left: auto;
  top: -1px;
  right: -1px;
  display: flex;
  width: fit-content;
  position: relative;
  overflow-x: hidden;
  border-radius: 5px;
  overflow-y: hidden;
  color: black;
  border: 2px solid #2c5c6900 !important;
}

.plotControlsContainer:hover {
  box-shadow: 0 0 9px 0px #2c5c69;
  border: 2px solid #2c5c69 !important;
}

.viewStyleName {
  position: relative;
  width: fit-content;
  text-align: right;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.65em;
  margin-top: 7px;
  margin-left: 5px;
  align-self: center;
}

.sourceElementName {
  position: absolute;
  width: fit-content;
  margin: auto;
  text-align: center;
  color: black;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.65em;
  align-self: center;
}

.traceCompareChannel {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: elipsis
}

.traceCompareValue {
  font-weight: 800;
  margin-bottom: 0;
  font-size: 1.65rem;
  max-height: 35px;
}

.traceCompareStatsRow {
  display: flex;
  min-height: 35px;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  border-bottom: 2px solid grey;
}
.traceStatsTitle {
  font-weight: 400;
  margin-bottom: 0;
  min-width: 18%;
  padding-left: .01rem;
  padding-top: .05rem;
  padding-bottom: .05rem;
  line-height: .8rem;
  font-size: .60rem;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: elipsis
}
.traceStatsValue {
  font-weight: 600;
  margin-bottom: 0;
  line-height: .8rem;
  min-width: 18%;
  padding: .05rem;
  font-size: .75rem;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: elipsis
}

.traceStatsValue.traceStatsMajor {
  font-weight: 700;
  font-size: .80rem;
}


.primaryCompareChannel {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 14px;
  box-shadow: 0 0 2px 0px #00e29f;
}

.traceCompare {
  position: relative;
  border: 1px solid #2c5c6900;
  z-index: 8;

}

.traceCompareControls {
  position: absolute;
  top: 0px;
  border: 1px solid #2c5c6900;
  z-index: 8;

}