.DirectionalDashbard {
  font-family: sans-serif;
  text-align: center;
  position: relative;
}

.tableContainer {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 400px;
  min-height: 138px;
  height: 50%;
  width: 70%;
  margin: auto;
}

.tableContainer .tableActions {
  position: absolute;
  display: flex;
  top: 4px;
  right: 4px;
  height: 30px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.metricsContainer {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  min-height: 400px;
  height: 50%;
  width: 20%;
}

.tfPlotContainer {
  position: relative;
  max-height: 550px;
  height: 100%;
  min-height: 300px;
  width: 55%;
  max-width: 550px;
  margin: auto;
  margin-top: 0px;
  padding-top: 25px;
  border-right: 2px solid #0a2d4779;
  border-left: 2px solid #0a2d4779;
}




.tfPlot {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  max-height: 500;
  height: 20%;
  width: 80%;
}

.histogramPlot {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  max-height: 500;
  height: 20%;
  width: 20%;
}

.slidePCTLabel {
  line-height: 2;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.15rem;
  color: #ffffff;
  text-align: center !important;
}

.metricContainer {
  margin-top: 8px;
  margin-left: 5px;
  margin-right: 12px;
  position: relative;
  align-self: center;
  max-height: 550px;
  height: 20%;
  min-height: 300px;
  width: 30%;
}

.topSection,
.mainBottomSection {
  display: flex;
  margin-top: 10px;
}

.directionalDivergenceCard {
  margin-top: 4px;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  position: relative;
  max-height: 500px;
  min-height: 250px;
  max-width: 300px;
  min-width: 250px;
  width: 25%;
}

.divergenceBar {
  width: 100%;
  margin-right: 0px;
  border-bottom: 1px solid #0a2d47;
}

.divergenceRow {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 5px;
  justify-content: space-between;
}

.divergenceMetric {
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
}

.divergenceMetricLabel {
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 13px;
  margin-left: 2px;
  margin-right: 6px;
  margin-bottom: 0.15rem;
  text-align: center !important;
  color: #323232
}

.metricLabelSetWidth {
  min-width: 25px
}

.sparkParamContainer {
  margin-top: 12px;
  margin-left: 5px;
  position: relative;
  display: flex;
  width: 35%;
  /* width: calc(100% - 130px); */
}


.sparkParamTitleContainer {
  position: relative;
  width: max-content;
  position: relative;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0px, 40px));
  column-gap: 1rem;
  row-gap: .2rem;
  justify-items: end;
  counter-reset: grid-items;

}

.sparkParamRow {
  position: relative;
  width: 100%;
  display: flex;
  flex: no-wrap;
  align-self: center;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: auto;
}

.sparkParamTitle {
  min-width: 90px;
  font-family: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  align-self: center;
  font-weight: 600;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 0.35rem;
  text-align: right !important;
  color: #323232
}

.sparkParamValue {
  /* min-width: 75px; */
  height: 35px;
  font-family: inherit;
  text-transform: uppercase;
  align-self: center;
  font-weight: 700;
  font-size: 20px;
  margin-left: 6px;
  margin-right: 2px;
  margin-bottom: auto;
  margin-top: auto;
  text-align: right !important;
  color: #323232
}

.sparkParamUnit {
  min-width: 70px;
  height: 35px;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
  margin-right: 6px;
  align-self: flex-end;
  text-align: left !important;
  color: #323232
}

.sparkParamPlotContainer {
  position: relative;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0px, 40px));
  column-gap: 1rem;
  row-gap: .2rem;
  justify-items: end;
  counter-reset: grid-items;
  width: calc(100% - 145px)
}

.sparkParamPlot {
  width: 100%;
  justify-content: center;
  height: 100%
}

.wellPlotAppContainer {
  display: block;
  width: calc(100% - 130px);
  max-width: 800px;
  border-radius: 8px;
  margin-right: 20px;
}

.wellPlotChartContainer {
  display: grid;
  /* grid-auto-columns: minmax(0, 1fr); */
  /* grid-auto-flow: column; */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: .5rem;
  row-gap: 2rem;
  padding-top: 10px;
  padding-right: 20px;
  position: relative;
  margin: auto;
  min-height: 260px;
  justify-items: center;
  counter-reset: grid-items;
  width: 100%;

}

.wellPlotLegend {
  position: relative;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  margin-top: 20px
}