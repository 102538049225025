.errorStats {
  position: relative;
  width: 100%;
}

.errorLevelSelection {
  position: relative;
  display: flex;
  width: 80%;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 1000px,
}

.dataErrorTable {
  position: relative;
  min-width: 500px;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 1000px,
}

.dataErrorTable .errorCategories {
  position: absolute;
  display: flex;
  top: 4px;
  right: 4px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 150,
}

.dataErrorTable .errorCount {
  color: #ffffff !important;
  /* font-family: 'Poppins'; */
  position: absolute;
  display: flex;
  top: -40px;
  left: 8px;
  font-size: 18px;
  border-radius: 10px;
  height: 30px;
  min-width: 40px;
  max-width: 100px;
  justify-content: center;
  align-self: center;
  /* text-align:center; */
  background-color: #ff5600D2;

}


.dataErrorTable .downloadErrors {

  position: absolute;
  display: flex;
  top: -48px;
  right: -8px;
  font-size: 18px;
  border-radius: 10px;
  height: 30px;
  min-width: 40px;
  max-width: 40px;
  justify-content: center;
  align-self: center;

}

.witsChannelDetails {
  position: relative;
  width: 100%;
  display: flex;
}

.channelGaugeChart {
  position: relative;
  height: 80px;
  width: 10% !important;
  max-width: 150px;
  justify-content: center;
  align-self: center;
  text-align: center !important;
}

.channelSparkChart {
  position: relative;
  height: 80px;
  width: 60%;
  min-width: 450px;
  margin-left: 15px;
  justify-content: center;
  align-self: center;
  text-align: center !important;
}

.witsChannelDetails .witsChannelName {
  width: 15%;
  min-width: 80px;
  max-width: 220px;
  line-height: 5;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.witsChannelDetails .witsChannelHeader {
  width: 15%;
  min-width: 80px;
  max-width: 220px;
  line-height: 2;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.witsChannelDetails .channelSparkName {
  width: 60%;
  line-height: 2;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.witsChannelDetails .channelGaugeName {
  width: 10% !important;
  max-width: 150px;
  line-height: 2;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: rgb(58, 58, 58);
  text-align: center !important;
}

.witsChannelDetails .channelNameNoData {
  width: 12%;
  min-width: 150px;
  line-height: 5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: #ea4343;
  text-align: center !important;
}

.witsChannelDetails .channelNoData {
  width: 60%;
  line-height: 5;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 0.45rem;
  color: #ea4343;
  text-align: center !important;
}