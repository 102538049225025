.designStudyTitle {
  /* font-family: inherit; */
  margin-left: 5px;
  line-height: 1;
  font-size: 24px;
  text-transform: uppercase;
  /* font-weight: 480; */
  color: #005A3D;
  text-shadow: 0.5px 0.5px #00e29e5e;
  text-align: center !important;
}

.hoverTimeCard {
  position: absolute;
  right: 20px;
  top: 40px;
  color: black;
  font-size: 20px;
}

.isolationButton {
  position: relative
}

.isolationButton .isolationToolbarContainer {
  position: absolute;
  top: 30px;
  left: 22px;
  overflow: show ! important;
  z-index: 110;
}



.isolationsToolbarTitle {
  position: relative;
  padding: 3px;
  padding-left: 20px;
  transition: .35s ease-in-out;
  margin-right: auto;
  margin-bottom: 3px;
  color: black;
  font-size: 0.8125rem;
  line-height: 1.4em;
  font-weight: 800 !important
}

.isolationButton .isolationsToolbar {
  background: #ffffffB3;
  border-radius: 8px;
  min-width: 250px;
  max-width: 40%;
  width: fit-content;
  min-height: 280px;
  z-index: 100;
  /* height: fit-content; */
  max-height: 230px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid var(--success);
  padding-bottom: 10px;
}

.isolationsToolbar:hover>.CloseBtn {
  position: absolute;
  display: block;
  padding-top: 3px;
  cursor: pointer
}

.isolationsToolbar::-webkit-scrollbar {
  height: 0;
  width: 10px;
}

.isolationsToolbar::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

.isolationsToolbar::-webkit-scrollbar-track-piece,
.isolationsToolbar::-webkit-scrollbar-track {
  background-color: transparent;
  height: 8px;
}

.isolationToggleList {
  /* overflow-x: hidden; */
  margin-right: 30px;
  text-align: left
}