.svr-parent {
  position: relative;
  margin-bottom: 20px;
  display:flex
}


.svr-container {
    bottom: 0;
    width: 100%;
    background: #3e3e3eD6;
    height: 18px;
    z-index: 1003;
    transition: .5s ease-in-out;
    border-top: 2px solid #00e29f;
    box-shadow: 0 0 9px 0px #2c5c69;
  }
  .svr-label-container {
    width: 100%;
    display:flex
  }
  .svr-label-col {
    margin:auto;
    width: 33%;
  }
  .svr-title-text{
    /* font-family: inherit; */
    margin-left: 5px;
    line-height: 1;
    font-size: 20px;
    font-weight: 550;
    color: #3e3e3eD6;;
    text-align: center !important;
  }

.svr-label-text{
    /* font-family: inherit; */
    line-height: 1.2;
    font-size: 14px;
    font-weight: 580;
    color: #3e3e3eD6;;
    text-align: center !important;
  }
  .svr-sliding-rop{
    /* font-family: inherit; */
    margin-left: 5px;
    line-height: 1;
    font-size: 24px;
    text-transform: uppercase;
    /* font-weight: 480; */
    color: #005A3D;
    text-shadow:0.5px 0.5px  #00e29e5e;
    text-align: center !important;
  }

.plot-controls-container{
  width: 100%;
  display:flex
}

.plot-controls-toggle-buttons{
  width: 10%;
  min-width:100px;
  align-self:center
}
.plot-controls-svr-group{
  display:flex;
  transition: .5s ease-in-out;
  align-self:center;
  margin: 2px;
  color: #3e3e3eD6;;
}

.plot-controls-svr-group .segment-button{
  transition: .5s ease-in-out;
  width: 10%;
  min-width: 40px;
  margin: 2px;
  align-self:center;
  color: #3e3e3eD6;
}
.plot-controls-segment-select{
  /* width: 37%;
  min-width: 300px; */
  margin: 1px;
  transition: .5s ease-in-out;
  display:flex
}
.plot-controls-segment-select .bha-select{
  width: 300px;
  min-width: 260px;
  transition: .5s ease-in-out;
  margin: 2px;
}
.plot-controls-segment-select .formation-select{
  width: 240px;
  transition: .5s ease-in-out;
  min-width: 190px;
  margin: 2px;
}

.plot-controls-segment-select .segment-button{
  width: 10%;
  min-width: 50px;
  margin: 2px;
  align-self:center
}